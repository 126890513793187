/* line 17, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */
@import "main";

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
.box-mobile {
  //margin: 0 -30px 0 -8px !important;
  padding: 4px;
}

/*.menu-button.selected{
    box-shadow: inset 0px 0px 9px black;
}
.menu-button.selected>label{
    color:#a6b9ff;
}*/

/**
 * Fix fonts that render as bold in Firefox
 *
 * Put this near the top of your style.css
 * Before any overriding styles
 */

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
}

/**
 * Firefox specific rule
 */
@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}


.clearfix {
  display: inline-block;
}

/* start commented backslash hack \*/

* html .clearfix {
  height: 1%;
}

.clearfix {
  display: block;
  &:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
  }
}

/* close commented backslash hack */

/*This cancels the mobile long press stuff allowing us to use long press*/

.scene-button {
  /*     overflow:hidden; */
  /*     -webkit-touch-callout: none !important; */
  -webkit-user-select: none;
}

#scene-button-container {
  padding-bottom: 100px;
}

/* line 32, sass/partials/_layout.scss */
/* JUST A SMALL BUTTON IN THE TOP RIGHT OF A DD LI */

.tiny-dd-button {
  font-size: 16px;
  font-size: 1rem;
  width: 100px;
  height: 40px;
  float: right;
  text-align: center;
  background: #4f4f4f;
  margin: -12px 20px 7px 0px;
  color: white;
  cursor: pointer;
  letter-spacing: 1px;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

.dd-li-close {
  position: relative;
  /*     top:2px; */
  right: -40px;
}

.locations-features-dropdown-string .dd-li-close {
  position: relative;
  right: -8px;
  top: 0px;
}

/* SCENE DROPDOWN STUFF */

/* The style for the whole dropdown */

.scene-settings-dropdown {
  background-color: rgba(62, 62, 62, 1);
  color: white;
  width: 150px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 28px;
  top: 28px;
  z-index: 10;
  border-radius: 2%;
  vertical-align: middle;
  box-shadow: -2px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  .bottom-border {
    border-bottom: 1px solid rgba(98, 98, 98, 1);
  }
  li {
    /*     height:40px; */
    cursor: pointer;
    vertical-align: middle;
  }
}

/* Items not at the bottom need a morder */

/* Restricts the heigh of the list items */

.scenes-dropdown-string {
  margin: 0% 0% 0% 7%;
  padding: 0% 3% 0% 4%;
}

.scene-settings-dropdown .bottom-border {
  border-bottom: 1px solid rgba(98, 98, 98, 1);
}

/* END SCENE DROPDOWN STUFF */

.delete-group-icon {
  margin: 0% 0% 0% 4%;
  padding: 5px;
  position: relative;
  left: 8px;
  &:hover {
    color: red;
  }
}

/*--------------------------------------------------------------------- GENERAL CSS */

.right {
  float: right;
}

.left {
  float: left;
}

.bold {
  font-weight: bold;
}

/* line 58, sass/partials/_layout.scss */

.success, .error {
  position: relative;
  font-size: 1rem;
  /*border: 1px solid rgba(255, 255, 255, 0.46);
  /* margin-bottom: 20px; */
  padding: 25px 20px 25px 15px;
}

/* line 64, sass/partials/_layout.scss */

.success {
  color: white;
  background-color: #2d2d2d;
}

/* line 6, sass/partials/_base.scss */

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  &:after, &:before {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }
}

/* line 15, sass/partials/_base.scss */

.clear {
  &:before {
    content: ' ';
    display: table;
  }
  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
  *zoom: 1;
}

/* line 20, sass/partials/_base.scss */

/* line 24, sass/partials/_base.scss */

/* line 28, sass/partials/_base.scss */

.clearer {
  clear: both;
  height: 0;
  line-height: 0;
  display: block;
}

/* line 35, sass/partials/_base.scss */

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* line 41, sass/partials/_base.scss */

a {
  text-decoration: none;
  &:focus, &:hover, &:active {
    outline: 0;
  }
}

/* line 50, sass/partials/_base.scss */

/* line 54, sass/partials/_base.scss */

/* line 22, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

html {
  line-height: 1;
}

.pointer {
  cursor: pointer;
}

.clear-right {
  clear: right;
}

.clear-left {
  clear: left;
}

.clear-both {
  clear: both;
}

#apple-header-bar {
  position: fixed;
  width: 200%;
  height: 20px;
  top: 0px;
  left: 0px;
  z-index: 999;
}

.ios {
  top: 20px;
  height: 97% !important;
}

/* line 59, sass/partials/_base.scss */

input:focus, button {
  /*-webkit-appearance: none;*/
  outline: 0;
}

/* line 64, sass/partials/_base.scss */

iframe {
  display: block;
}

/* line 68, sass/partials/_base.scss */

.hidden {
  display: none;
}

.shown {
  display: block;
}

a:visited {
  color: none;
}

#save-success-text {
  background: white;
  z-index: 999;
  display: none;
  text-align: center;
  width: 100%;
  padding: 5px;
  text-size: 15px;
  bottom: 52px;
  left: 0px;
  box-shadow: 1px 1px 1px black;
  position: absolute;
  color: rgba(75, 137, 217, 1);
}

.blue-text {
  color: rgba(75, 137, 217, 1) !important;
}

.help-text-italic {
  font-style: italic;
}

.help-text {
  color: #6bb9f0;
}

.help-text-param {
  color: #3498Db;
}

.help-text-desc {
  font-size: 80%;
}

/* line 64, sass/partials/_nav.scss */

.orange {
  background: #d1952b;
}

/* line 68, sass/partials/_nav.scss */

.grey {
  background: dimgrey;
}

/* line 72, sass/partials/_nav.scss */

.blue {
  background: #088fff !important;
}

.header-blue {
  background: #0074d4;
}

.header-grey {
  background: #4f4f4f;
}

.white {
  background-color: white !important;
}
.write-success{
  background-color:green !important;
}
.write-error{
  background-color:red !important;
}
.green {
  color: #23c85f;
}

.red {
  color: #fc4d5f;
}

.yellow {
  color: yellow;
}

.white-text {
  color: white;
}

.grey-text {
  color: dimgrey;
}

/* line 69, sass/partials/_layout.scss */

.error {
  color: #D8000C;
  background-color: #FFBABA;
}

.success {
    color: green;
    background-color: #b3cebd;
}

/*-------------------------------------------------------- NAVIGATION MENU GENERAL  */



/* line 1, sass/partials/_nav.scss */

#nav-icons {
  // position: absolute;
  // top: 2px;
  // right: -65px;
  #devices, #link-groups, #settings, #cloud-sync {
    display: none;
  }
}

/* line 9, sass/partials/_nav.scss

#cloud-sync-button {
  cursor: pointer;
  width: 145px;
  margin: auto;
  margin-top: 14px;
  color: white;
  background: #4f4e4f;
  box-shadow: 0px 0px 1px black;
  border-radius: 5px;
  text-align: center;
  float: none;
  padding: 10px;
  i{
    margin-right:5px;
  }
}*/

/* line 13, sass/partials/_nav.scss */

#nav-icons a {
  margin-bottom: 3px;
  text-align: center;
  color: white;
  display: block;
  cursor: pointer;
  width: $nav-icons-width;
  border-radius: 0px 3px 3px 0px;
  position: relative !important;
  height: 65px;
  line-height: 70px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

#utility-nav a i {
  top: 0px;
}

#locations, #cron, #sync, #delete-devices {
  box-shadow: 3px 1px 9px rgba(37, 37, 37, 0.69);
  position: relative;
  z-index: 0;
}

/* line 13, sass/partials/_nav.scss */

/* line 38, sass/partials/_nav.scss */

#nav-icons {
  .spin{
    animation: spin-animation $spin-animation-speed infinite linear;
  }
  @keyframes spin-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
  .nav-icons-container {
    height: 88vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .utility-nav {
    margin-bottom: 7px;
    a {
      color: white;
      // background: #202020;
      // box-shadow: inset 5px 0px 11px -7px black;
      /*inset 6px 0px 12px -7px black;*/
      &.active {
        position: relative;
        z-index: 997;
        // box-shadow: 1px 4px 1px rgba(37, 37, 37, 0.69);
      }
    }
  }
  .main-nav a.active {
    position: relative;
    z-index: 997;
    // box-shadow: 1px 4px 1px rgba(37, 37, 37, 0.69);
  }
  .utility-nav a.active {
    // background: #202020;
    box-shadow: none;
  }
  .main-nav a.active {
    // background: #088fff;
  }
  .utility-nav a:hover {
    //background: #202020;
    position: relative;
    z-index: 996;
    box-shadow: none;
  }
  .main-nav a {
    // background: #0074d4;
    // box-shadow: inset 5px 0px 11px -7px black;
    &:hover {
      //background: #088fff;
      position: relative;
      z-index: 996;
      box-shadow: none;
    }
  }
}

#mqtt-icon-disabled {
  opacity: 0.35;
  filter: alpha(opacity=40);
}

/* line 41, sass/partials/_nav.scss */

/* line 46, sass/partials/_nav.scss */

/* line 53, sass/partials/_nav.scss */

/* line 57, sass/partials/_nav.scss */

/* line 76, sass/partials/_nav.scss */

#load-nav {
  display: none;
  nav {
    background: #078fff;
  }
}

/* line 79, sass/partials/_nav.scss */

.toggle-icon {
  opacity: .5;
  margin-right: 21px;
}

/*-------------------------------------------------- LOC/DEV/SCENE EDIT OPTIONS GENERAL  */

.option-door {
  cursor: pointer;
}

#scene-list-item-option-doorway, #device-list-item-option-doorway, #locations-list-item-option-doorway {
  height: 100%;
}

#device-list-item-option-doorway {
  height: 101%;
  border-bottom: 1px solid #077ad9;
}

#scene-list-item-option-doorway {
  height: 102%;
  border-bottom: 1px solid #077ad9;
}

.scene-list-item .option-door i {
  position: relative;
  top: 2px;
}

.delete-list-item:hover {
  color: #4f4f4f;
  cursor: pointer;
}

.edit-locations-list-button:hover, .save-locations-list-button:hover {
  cursor: pointer;
  color: #4f4f4f;
}

.edit-device-list-button:hover, .save-device-list-button:hover, .edit-scene-list-button:hover, .save-scene-list-button:hover, .scene-list-item {
  cursor: pointer;
}

.edit-scene-list-button, .edit-device-list-button, .save-scene-list-button, .save-device-list-button {
  color: white;
  text-align: center;
  width: 40px;
  height: 100%;
  top: 0;
  background: rgba(38, 86, 127, 0.38);
  left: 85px;
  float: right;
  /* border-left: 1px solid #077ad9; */
  /* border-right: 1px solid #077ad9; */
  /* box-shadow: inset 4px 0px 9px -4px black; */
}

/*------------------------------------------- LOC EDIT DRAWER OPTIONS */

.locations-list-item-option-container {
  left: 1px;
  height: 100%;
  width: 34px;
  float: right;
  position: relative;
  z-index: 997;
  background: dimgrey;
}

.sub-locations-list-item-option-container {
  background-color: #696969;
  left: 52px;
  height: 100%;
  float: right;
  position: relative;
}

#locations-list-item-option-doorway {
  width: 100%;
  /* left: 5px; */
  /* background: green; */
  padding-left: 14px;
  margin-right: 0;
  font-size: 1.2rem;
  position: absolute;
  z-index: 994;
  /* left: 25px; */
  /* box-shadow: 16px 3px 13px black; */
}

.save-locations-list-button, .edit-locations-list-button {
  color: white;
  text-align: center;
  width: 40px;
  height: 100%;
  top: 0;
  left: 33px;
  background: #a09f9f;
  /* left: 83px; */
  float: right;
  z-index: 0;
  /* border-right: 1px solid black; */
  border-left: 1px solid #686868;
  position: absolute;
  /* left: 120px; */
}

.delete-locations-button {
  color: white;
  background: #ff4f4f;
  text-align: center;
  width: 40px;
  height: 100%;
  top: 0;
  float: right;
  position: absolute;
  /* left: 190px; */
  left: 113px;
}

.locations-li-option-door {
  color: #959595;
}

.delete-loc-button {
  left: 1%;
  color: red;
  top: 13px;
  margin-right: 8px;
  padding-bottom: 0;
  padding-top: 12px;
}

.edit-loc-list-button, .done-loc-list-button {
  position: absolute;
  left: 85%;
}

.edit-loc-name-list, .save-loc-name-list {
  color: white;
  font-size: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-bottom: 0px;
  margin-left: 10px;
  bottom: 0px;
  padding-right: 10px;
  z-index: 10;
}

#edit-loc-name, #save-loc-name, #save-device-name, #edit-device-name {
  font-size: 50%;
  margin-left: 5px;
  text-align: right;
  position: relative;
  top: -20px;
  color: grey;
  cursor: pointer;
}

/*------------------------------------------- DEV EDIT DRAWER OPTIONS */

.device-list-item-option-container {
  background-color: #088fff;
  left: 120px;
  height: 100%;
  float: right;
  position: relative;
  left: 85px;
}

#device-list-item-option-doorway {
  width: 116px;
  font-size: 1.2rem;
}

.device-li-option-door {
  position: absolute;
  top: 22px;
  margin-left: 10px;
  margin-right: 10px;
  color: rgba(130, 198, 255, 1);
  &:before {
    font-size: 25px;
  }
}

.edit-device-name-list, .save-device-name-list {
  position: relative;
  margin-left: 2px;
  top: 1%;
  margin-left: 10px;
  font-size: 100%;
  padding-bottom: 0px;
  padding-top: 10px;
  margin-bottom: 0px;
  bottom: 0px;
  cursor: pointer;
}

.delete-device-button, .edit-device-list-button, .save-device-list-button {
  padding-top: 12px;
}

#delete-devices {
  height: 100%;
  position: relative;
  bottom: 0px;
  width: 100%;
}

.done-device-button {
  float: right;
  position: relative;
  top: 15px;
  cursor: pointer;
}

/*------------------------------------------- SCENE EDIT DRAWER OPTIONS */

.scene-list-item-option-container {
  background-color: #088fff;
  left: 85px;
  height: 102%;
  float: right;
  position: relative;
  border-bottom: 1px solid #077ad9;
}

.scene-li-option-door {
  margin: 0 15px 0 15px;
  color: rgba(130, 198, 255, 1);
  &:before {
    font-size: 25px;
  }
}

.menu-title {
  font-size: 2rem;
  margin: 0;
}

#edit-scene-name, #save-scene-name {
  font-size: 50%;
  margin-left: 5px;
  text-align: right;
  position: relative;
  cursor: pointer;
}

.edit-scene {
  position: absolute;
  top: 57px;
  right: 25px;
}

/*.edit-device-list-button, .done-device-list-button{
    padding:10px 10px 10px 10px;
    cursor:pointer;
}*/

/*-------------------------------------------- LOGIN NAV MENU */

.login-header {
  background: #4f4f4f9e;
  border-bottom: 1px solid rgba(79, 79, 79, 1);
  width: 100%;
  height: 50px;
  padding-top: 10px;
  text-align: center;
  box-shadow: 1px 1px 7px rgba(37, 37, 37, 0.69);
}

// #login-status {
//   text-align:left;
//   width: 100%;
//   border-radius: 3px;
//   > p {
//     border-radius: 3px;
//   }
// }

/* line 74, sass/partials/_layout.scss */

.user-logout, .logout {
  background: dimgrey;
  float: right;
  margin: 0px;
  cursor: pointer;
  height: 26px;
  line-height: 1.8;
  width: auto;
  box-shadow: 0px 0px 2px 1px black;
  padding: 0px 5px;
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.8rem;
}

.user-logout:hover, .logout:hover, .user-logout:hover, .logout:hover {
  background: #4f4f4f;
  color: white;
}

#site-search-bar-container {
  width: 100%;
  position: relative;
  top: 55%;
  /* 	position: relative; */
  border-radius: 1%;
}

.site-list-item:hover {
  background: #4f4f4f;
}

#site-list-search-x {
  left: 88%;
  bottom: 0px;
  padding: 10px;
  cursor: pointer;
}

#select-site-container {
  top: 0px;
  bottom: 0px;
  max-height: 650px;
  background-color: rgba(122, 122, 122, 0.6);
  border-radius: 1%;
  height: 600px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: background 0.15s ease;
  -moz-transition: background 0.15s ease;
  -ms-transition: background 0.15s ease;
  -o-transition: background 0.15s ease;
  transition: background 0.15s ease;
}

#site-list {
  cursor: all-scroll;
  -webkit-overflow-scrolling: touch;
  li {
    color: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid rgba(98, 98, 98, 1);
    padding-top: 5px;
    cursor: pointer;
  }
  span {
    font-size: 15px;
    padding-left: 10px;
    cursor: pointer;
    display: block;
  }
}
#site-list-container{
  display: block;
  position: absolute;
  width: 100%;
}

.no-match {
  display: none;
}

#site-list-search-bar {
  display: block;
  width: 100%;
  bottom: 0px;
  position: relative;
  background-color: #d8d8d8;
  margin-bottom: -5px;
  padding:15px;
  font-size: 22px;
  border-radius: $default-border-radius;
  height: 50px;
}

/*---------------------------------- LOCATIONS NAV MENU */

#locations {
  height: 100%;
}

.locations-main-header, #login-header {
  background: #4f4f4fd6;
  width: 100%;
  height: 50px;
  padding-top: 10px;
  text-align: center;
  margin-bottom: 5px;
  // box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
}

.refresh-data {
  font-size: 1.5rem;
  cursor: pointer;
  position: relative;
  right: 0.5%;
  /* top: 5px; */
  height: 100%;
  float: right;
  font-size: 1.7rem;
  color: white;
  /* left: 90%; */
  top: -12px;
  width: 13%;
  padding-top: 16px;
  position: relative;
  cursor: pointer;
}

#locHeaderInfo {
  height: 54px;
  padding: 22px 15px 0 20px;
  border-bottom: 1px solid rgba(79, 79, 79, 0.5);
}

/*------------------------------- LOCATION NAV MENU HEADER*/

#locations .menu-title {
  font-size: 1.8rem;
}
.not-root-loc {
  height: 125px !important;
}

#root-location-header {
  padding: 0;
  position: relative;
  display: inline;
  top: 0px;
  width: 74%;
  font-size: 2.0rem !important;
}

.root-loc-name {
  left: 0px !important;
  float: left;
  margin-left: 10px;
  font-size: 30px;
}

.add-loc {
  font-size: 1.9rem;
  cursor: pointer;
  position: relative;
  right: 1%;
  bottom: 8px;
  z-index: 101;
}

#root-location-header {
  .edit-location-name, .save-location-name {
    font-size: 1rem;
    position: relative;
    top: -4px;
    left: 8px;
    color: #959595;
    cursor: pointer;
  }
}

/*------------------------------- LOCATION NAV MENU LIST*/

.loc-list-ul {
  color: white;
  overflow: auto;
  max-height: 100%;
  width: 100%;
  height: 85%;
  position: absolute;
  right: 0;
  padding: 0px 0px 100px 0px;
  &::-webkit-scrollbar {
    /* This is the magic bit */
    display: none;
  }
}

#locations ul {
  clear: left;
}

.loc-list-li {
  cursor: pointer;
}

.loc-list-item {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.loc-menu-back-btn-container {
  width: 100%;
  height: 50px;
  background: #4f4f4f;
  position: absolute;
  bottom: 0;
  z-index: 999;
  font-size: 2rem;
  padding: 10px 20px;
  box-shadow: 0px -2px 7px rgba(37, 37, 37, 0.69);
  opacity: 0;
  color: white;
}

.loc-menu-back-btn {
  position: relative;
  z-index: 999;
  cursor: pointer;
  &:hover {
    color: rgba(37, 37, 37, 1);
  }
}

.location-path-ul {
  display: none;
  padding-left: 42px;
  li {
    width: 82%;
    border-bottom: 1px solid rgba(53, 53, 53, 0.16);
    color: rgba(255, 255, 255, 0.56);
    i {
      padding-right: 5px;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

.loc-name-list {
  outline: none;
  padding: 0px 10px 0px 7px;
  position: relative;
  z-index: 100;
  left: 15px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s;
  -moz-transition: left 0.2s;
  -ms-transition: left 0.2s;
  -o-transition: left 0.2s;
  transition: left 0.2s;
}

.loc-list-ul {
  li {
    position: relative;
    height: 47px;
    /* width: 375px; */
    left: 0px;
    border-bottom: 1px solid rgba(79, 79, 79, 0.7);
    -webkit-backface-visibility: hidden;
    -webkit-transition: left 0.3s;
    -moz-transition: left 0.3s;
    -ms-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;
  }
  .active-list-item {
    left: -119px;
    -webkit-backface-visibility: hidden;
    -webkit-transition: left 0.3s;
    -moz-transition: left 0.3s;
    -ms-transition: left 0.3s;
    -o-transition: left 0.3s;
    transition: left 0.3s;
    .loc-name-list {
      left: 125px;
      -webkit-backface-visibility: hidden;
      -webkit-transition: left 0.3s;
      -moz-transition: left 0.3s;
      -ms-transition: left 0.3s;
      -o-transition: left 0.3s;
      transition: left 0.3s;
    }
  }
  li:hover {
    background: rgba(79, 79, 79, 1);
    box-shadow: inset 2px 0px 7px rgba(37, 37, 37, 0.69);
    border-bottom: none;
    > .option-door {
      box-shadow: -8px 0px 7px -3px rgba(37, 37, 37, 0.69);
    }
  }
}

.device-list-el:hover > .option-door, .scene-list-item:hover > .option-door {
  box-shadow: -10px 0px 9px -10px rgba(0, 0, 0, 0.36);
}

.loc-list-ul > li span {
  font-size: 1.2rem;
}

.info-icon {
  margin: 0px 5px 0px 5px;
}

.location-info label {
  font-size: 1.5rem;
  vertical-align: middle;
}

.loc-list-ul {
  overflow-x: hidden;
  .border-right {
    border-right: 1px solid  #696969;
  }
}

.child-loc-ul li .show-children-container {
  left: 310px;
}

.warning-ind {
  color: white;
  position: relative;
  left: 10px;
}

.loc-with-child {
  cursor: pointer;
}

.show-child-locations {
  width: 40px;
  height: 100%;
}

.loc-breadcrumbs li {
  float: left;
  color: #959595;
  padding-right: 5px;
  position: relative;
  bottom: 6px;
  z-index: 998;
  cursor: pointer;
}

.crumb-slash {
  font-size: 1.4rem;
  color: rgba(189, 181, 181, 0.49) !important;
}

.loc-breadcrumbs {
  color: #8eb8d9;
  left: 10px;
  bottom: 11px;
  position: relative;
  clear: none !important;
}

.current-crumb {
  top: 0px;
  font-size: 1.4rem;
}

.loc-breadcrumbs {
  .icon-level-up {
    color: #959595;
    padding: 16px 17px 8px 7px;
    float: left;
    transform: rotate(90deg);
  }
  li {
    span, i {
      color: white;
    }
    span {
      &:hover, &:active {
        color: #8eb8d9;
      }
    }
    i {
      &:hover, &:active {
        color: #8eb8d9;
      }
    }
  }
}

/*.border {
    border-bottom: 1px solid rgba(79,79,79,1);
    position: absolute;
    width: 98%;
    left: 27px;
    padding-left: 16px;
    float: right;
}*/

.loc-name-list, .loc-name, .device-name-list, .scene-name-list {
  cursor: text;
}

.show-children-container {
  position: absolute;
  width: 79px;
  left: 327px;
  top: 0px;
  background: dimgrey;
  height: 100%;
  border-left: solid 1px #4f4f4f;
}

.show-children-button {
  width: 30px;
  height: 33px;
  text-align: center;
  padding: -2px;
  position: absolute;
  top: 18px;
  right: 20px;
  color: #959595;
}

.loc-list-li .location-marker {
  font-size: 1.35rem;
  padding: -2px;
  position: absolute;
  top: 10px;
  left: 28px;
  color: #959595;
}

.child-1 {
  .state-ind {
    right: 57px;
  }
  .lev-ind {
    left: 255px;
  }
}

.child-2 .occ-indicator {
  right: 78px;
}

.child-loc-ul {
  position: absolute;
  top: 40px;
  min-height: 35px;
  margin-left: 0px;
  width: 388px;
  margin-top: 10px;
  padding-left: 17px;
  padding-bottom: 150px;
  li {
    display: none;
    width: 373px;
  }
}

.occ-ind {
  position: absolute;
  right: 80px;
}

.state-ind {
  position: absolute;
  top: 14px;
  right: 40px;
  margin-left: 9px !important;
}

.daylight-ind {
  position: absolute;
  top: 14px;
  right: 60px;
}

.lev-ind {
  color: white;
  font-size: 14px;
  top: 2px;
  left: 4px;
  position: relative;
}

/* line 53, sass/partials/_layout.scss */

.parent-loc {
  font-size: .9rem;
}

.scrollable-content-locations {
  position: relative;
  bottom: 40px;
  margin-top: 50px;
}

/*------------------------------------- SCENE NAV MENU */

#scenes .header-div {
  height: auto;
}

.scenes-main-header {
  background: #0074d49e;
  box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  text-align: center;
}

#scene-list-item-option-doorway:last-child {
  border-bottom: none;
}

.scenes-main-header .menu-title {
  /* float: left; */
  position: relative;
  /* left: 34px; */
  display: inline-block;
  top: 9px;
  width: 74%;
  font-size: 1.8rem !important;
}

#scenes-search-bar-container {
  width: 100%;
  position: relative;
  top: 32%;
  padding: 0 15px;
  margin-top: 15px;
  border-radius: 1%;
}

#scenes .section-title {
  padding: 6px;
  background: rgb(0, 116, 212);
  font-size: 1.5rem;
  /* margin-top: 5px; */
  margin-bottom: 0px;
  position: relative;
  z-index: 999;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.51);
  &.sub-loc {
    background: #088fff;
    /* padding: 14px; */
    padding: 14px 8px 14px 6px;
    box-shadow: none;
    font-weight: bold;
    margin: 10px 0px -2px 0px;
  }
}

.fixed {
  position: fixed;
  width: 441px;
  top: 105px;
}

.scene-container {
  margin: 10px 0px;
  position: relative;
  display: block;
  z-index: 500;
  max-height: 600px;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
}

.last-of-list {
  padding-bottom: 100px;
  max-height: 575px;
}

.scene-container::-webkit-scrollbar {
  display: none;
}

.delete-scene-button, .delete-device-button {
  color: white;
  text-align: center;
  text-align: center;
  width: 40px;
  height: 100%;
  background: #ff4f4f;
  top: 0;
  left: 30px;
  float: right;
}

.scenes-header {
  height: 160px !important;
}

body.dragging {
  cursor: grabbing !important;
  * {
    cursor: grabbing !important;
  }
}

ul {
  &.scenes-list li.placeholder, &.child-scene-list li.placeholder {
    position: relative;
    z-index: 999;
    top: 14px;
    left: 10px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #095390;
  }
  &.scenes-list {
    -webkit-overflow-scrolling: touch;
    .placeholder-container {
      height: 44px;
      /* margin-top: 10px; */
      border-bottom: 1px solid #077ad9;
      /* padding: 10px; */
    }
  }
  &.child-scene-list .placeholder-container {
    height: 44px;
    /* margin-top: 10px; */
    border-bottom: 1px solid #077ad9;
    /* padding: 10px; */
  }
  &.scenes-list li.placeholder:before, &.child-scene-listt li.placeholder {
    position: absolute;
    /** Define arrowhead **/
  }
}

li.placeholder {}

.dragged {
  position: absolute !important;
  opacity: 0.8 !important;
  z-index: 2000 !important;
  background-color: #0074d4 !important;
  .scene-list-item-option-container {
    display: none;
  }
}

h2.root-scenes {
  cursor: pointer;
}

.add-scene {
  position: absolute;
  cursor: pointer;
  z-index: 1000;
  left: 19px;
  top: 14px;
  color: white;
  font-size: 1.6rem;
}

.scenes-location-section {
  overflow: visible;
}

.scenes-list .active-list-item {
  right: 85px;
  -webkit-transition: right 0.2s ease-in-out;
  -moz-transition: right 0.2s ease-in-out;
  -ms-transition: right 0.2s ease-in-out;
  -o-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out;
  .scene-name-list {
    left: 110px;
    -webkit-transition: left 0.2s ease-in-out;
    -moz-transition: left 0.2s ease-in-out;
    -ms-transition: left 0.2s ease-in-out;
    -o-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out;
  }
}

.scene-name-list {
  left: 37px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-right: 20px;
  position: relative;
  display: inline-block;
  top: 2px;
  /* clear: left; */
  /* float: left; */
  white-space: nowrap;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s ease-in-out;
  -moz-transition: left 0.2s ease-in-out;
  -ms-transition: left 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

@-moz-document url-prefix() {
  .scene-name-list {
    top: 19px;
  }
}


#scenes-searchbar {
  width: 65%;
  display: block;
  margin-bottom: 2%;
  margin-top: 15px;
  margin-left: 143px;
  border-radius: 5px;
  margin-top: 15px;
  padding: 2px 10px;
  border: 4px solid white;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.36);
}

#scenes-search-bar-x {
  left: 88%;
  padding: 10px;
  cursor: pointer;
  top: 0px;
}

#scenes {
  height: 100%;
  #delete-multiple-scenes{
    margin-right: 15px;
  }
  #add-new-scene{
    margin-right: 15px;
  }
}

#scenes-main-list {
  overflow: scroll;
}

.scenes-main-list {
  overflow: auto;
  /*possibly need scroll*/
  max-height: 100%;
  width: 100%;
  /* padding-left: 13px; */
  padding-bottom: 400px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.scene-loc {
  font-size: .8rem;
}

/*.scenes-main-list::-webkit-scrollbar {
    /* This is the magic bit
    display: none;
}*/

.scenes-list li a, .scenes-main-list .scene-list-item a {
  color: white;
}

#scenes .menu-title-loc {
  font-size: 1.8rem;
  margin: 0;
  position: relative;
  top: 10px;
}

#scene-sort-div {
  position: absolute;
  width: 32%;
  /* top: 66px; */
  left: 7px;
  h3 {
    cursor: pointer;
    font-size: .9rem;
    background: #0074d4;
    padding: 10px 9px 8px 10px;
    position: relative;
    z-index: 99;
    border-radius: 5px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.65);
    i {
      position: absolute;
      right: 11px;
    }
  }
}

.scene-sort-by-list {
  display: none;
  color: white;
  font-size: 1.1rem;
  position: absolute;
  z-index: 10000;
  background: #0664b2;
  height: auto;
  width: 100%;
  left: 0px;
  top: 30px;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.65);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  li {
    padding: 0px 15px 0px 15px;
    border-bottom: 1px solid rgb(0, 116, 212);
    line-height: 35px;
    position: relative;
  }
}

.scene-sort-selected {
  div {
    position: absolute;
    background: #088fff;
    height: 100%;
    width: 9px;
    left: 0px;
    transition: all .2s;
    -webkit-transition: all .2s;
  }
  span {
    font-weight: bold;
  }
}

.scenes-sub-location-section {
  padding-left: 10px;
}

.scene-sort-by-list li:not(.scene-sort-selected):hover, .device-sort-by-li:not(.device-sort-selected):hover {
  background: #0478d8;
}

.scenes-lock-root, .scenes-lock {
  color: white;
  position: absolute;
  float: right;
  right: 21px;
  top: 14px;
  font-size: 1.6rem;
  cursor: pointer;
}

.scene-sort-by {
  font-weight: bold;
}

.scenes-lock {
  top: 14px;
  right: 20px;
}

.extra-scenes {
  display: none;
}

/*--------------------------------- DEVICE NAV MENU */

#devices {
  height: 100%;
  width: 100%;
  .header-div {
    margin-bottom: 10px;
  }
  .edit-list-button-container{
    button{
      margin-left: 8px;
      margin-right: 8px;
    }
    .done{
      background-color: #388e3c;
    }
  }
}

/*-------------------------- DEVICE MENU HEADER */

#devices-header-title {
  font-size: 1.2rem;
  display: none;
}

.header-div-bottom-border {
  border-bottom: 1px solid #077ad9;
  margin-top: 15px;
  width: 100%;
  top: 142px;
  position: absolute;
}

.main-header-container {
  width: 100%;
  text-align: center;
  display: inline-block;
  &.notch {
      padding-top: constant(safe-area-inset-top) !important;
      padding-top: env(safe-area-inset-top) !important;
  }
  &.remove-border {
    box-shadow: none;
  }
  .menu-title {
    float: left;
    position: relative;
    display: inline-block;
    top: 9px;
    text-align: left;
    margin-left: 10px;
  }
  .menu-breadcrumbs {
    color: white;
    display: block;
    text-align: left;
    font-size: 13px;
    width: 100%;
    .single-breadcrumb-container{
      display: inline;
    }
    .single-breadcrumb-container:hover{
      cursor: pointer;
      .location-name{
        text-decoration: underline;
      }
    }
    .location-name,.location-icon{
      display: inline-block;
      margin-left: 5px;
    }
    .secondary-breadcrumb-container{
      display: inline;
      .caret-right{
        margin-left: 8px;
        margin-right: 3px;
      }
      svg{
        display: inline;
      }
      div{
        display: inline;
      }
    }
  }
}

h2.root-devices {
  font-size: 1.5rem;
  cursor: pointer;
}

.device-list-button {
  height: inherit;
}

.device-extra-info {
  font-size: .8rem;
  position: absolute;
  /* left: 6px; */
  width: 201px;
}

.device-name-list {
  margin-left: 5px;
  font-size: 1.2rem;
}

#device-nav-list {
  li {
    color: white;
    margin-left: 0px !important;
    /* padding: 5px; */
    /* padding-left: 5px; */
    position: relative;
    right: 0px;
    -webkit-backface-visibility: hidden;
    -webkit-transition: right 0.2s ease-in-out;
    -moz-transition: right 0.2s ease-in-out;
    -ms-transition: right 0.2s ease-in-out;
    -o-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
  }
  .active-list-item {
    right: 80px;
    -webkit-transition: right 0.2s ease-in-out;
    -moz-transition: right 0.2s ease-in-out;
    -ms-transition: right 0.2s ease-in-out;
    -o-transition: right 0.2s ease-in-out;
    transition: right 0.2s ease-in-out;
  }
}

.scenes-list .active-list-item {
  right: 80px;
  -webkit-transition: right 0.2s ease-in-out;
  -moz-transition: right 0.2s ease-in-out;
  -ms-transition: right 0.2s ease-in-out;
  -o-transition: right 0.2s ease-in-out;
  transition: right 0.2s ease-in-out;
}

.device-list-button {
  color: white;
  margin-left: 17px;
  position: relative;
  left: 0px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s ease-in-out;
  -moz-transition: left 0.2s ease-in-out;
  -ms-transition: left 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

#device-nav-list .active-list-item .device-list-button {
  left: 65px;
  -webkit-backface-visibility: hidden;
  -webkit-transition: left 0.2s ease-in-out;
  -moz-transition: left 0.2s ease-in-out;
  -ms-transition: left 0.2s ease-in-out;
  -o-transition: left 0.2s ease-in-out;
  transition: left 0.2s ease-in-out;
}

.active-blue {
  background: #0074d4 !important;
  box-shadow: inset 1px 1px 7px -2px black !important;
}

#nav-icons i {
  line-height: 0 !important;
}

.dev-list-hidden {
  display: none !important;
}

/*.delete-device-button{
    font-size: 1.2rem;
    position: absolute;
    left: -20px;
    cursor: pointer;
    top: 17px;
    margin-right: 3px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 8px;
    padding-right: 10px;
}*/

.remove-device-icon {
  font-size: 1.4rem;
  position: absolute;
  cursor: pointer;
  color: red;
  top: 11px;
  margin-right: 3px;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 8px;
  padding-right: 10px;
  left: -20px;
}

.tagged-device-list li {
  position: relative;
  height: 70px;
  border-bottom: 1px solid #077ad9;
  margin-left: 10px !important;
}

#device-nav-list {
  box-shadow: -2px 0px 12px -3px black;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  max-height: 40px;
  width: 100%;
  position: relative;
  padding: 15px 5px 500px 0;
  /* padding-bottom: 100px; */
  &::-webkit-scrollbar {
    /* This is the magic bit */
    display: none;
  }
}

.device-list-info {
  left: 5px;
  top: 30%;
  width: 300px;
  margin-top: 5px;
  margin-bottom: 2px;
}

.has-groups .device-list-info {
  top: 47% !important;
}

.device-main-info {
  .fa-lightbulb {
    padding-left: 12px;
    right: 20px;
    top: 0px;
    font-size: 16px;
    position: absolute;
    cursor: pointer;
    padding: 5px 5px 5px 5px;
    margin-top: 20px;
  }
  .item-select {
    padding-right: 8px;
  }
}

.untagged-device-list-info {
  position: relative;
  top: 40%;
  color: rgba(157, 208, 243, 1);
  left: 3%;
}

.untagged-device-tags {
  position: absolute;
  left: 3%;
  top: 30%;
}

.untagged-device-loc {
  position: absolute;
  left: 3%;
  top: 60%;
}

#scan-alert-container {
  display: none;
  opacity: 1;
  width: 84vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  float: left;
  z-index: 999;
  position: fixed;
  text-align: center;
  padding-top: 130px;
}

#scan-alert {
  margin-right: 0px;
  text-align: center;
  padding-top: 40px;
  top: 50%;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
  background: green;
  width: 150px;
  height: 150px;
  z-index: 999;
  position: relative;
  border-radius: 99px;
  color: white;
  font-size: 5rem;
}

#scan-alert-container span {
  font-weight: bold;
  margin: auto;
  width: 80%;
  font-size: 1.5rem;
  position: relative;
  color: white;
}

.untagged-device-list-info > .device-rssi {
  left: 23px;
  top: -13px;
  position: relative;
  cursor: pointer;
  padding: 5px 5px 5px 5px;
  margin-top: 20px;
}

.edit-device {
  cursor: pointer;
  position: absolute;
  top: 2%;
  left: 76%;
  font-size: 32px;
  color: white;
  padding: 0px 10px 10px 20px;
}

.open-group-modal {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 24px;
  font-size: 2rem;
  color: white;
}

.devices-section-title {
  margin-top: 10px;
  padding: 5px 6px 10px 0px;
}

.tab {
  &:first-child {}
  &:hover:not(.tab-selected) {
    background: #696969;
    box-shadow: -3px 0px 1px 0px rgba(0, 0, 0, 0.21), 2px -1px 1px 0px rgba(0, 0, 0, 0.21);
    cursor: auto;
    z-index: 999;
    -webkit-transition: all .2s;
    -webkit-backface-visibility: hidden;
    cursor: pointer;
  }
}

#sort-devices {
  position: absolute;
  /* top: 66px; */
  cursor: pointer;
  left: 14px;
}

#device-sort-by-list {
  display: none;
  color: white;
  font-size: 1.1rem;
  position: absolute;
  z-index: 10000;
  background: #4f4f4fd6;;
  height: auto;
  width: 100%;
  left: 0px;
  top: 30px;
  box-shadow: 1px 2px 1px 1px rgba(0, 0, 0, 0.42);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

#sort-devices h3 {
  font-size: 16px;
  background:  #4f4f4fd6;;
  padding: 8px 23px 7px 6px;
  position: relative;
  z-index: 99;
  border-radius: 5px;
  box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.65);
}

.device-sort-by-selected {
  font-weight: bold;
  margin-left: 5px;
}

#sort-devices h3 i {
  position: absolute;
  right: 9px;
  font-size: 12px;
  top: 12px;
}

#device-sort-by-list {
  i {
    font-size: .8rem;
    cursor: pointer;
    position: relative;
    z-index: 999;
    bottom: 3px;
  }
  li {
    padding: 0px 15px 0px 15px;
    border-bottom: 1px solid rgb(0, 116, 212);
    line-height: 35px;
    position: relative;
    cursor: pointer;
    &:active {
      background: rgb(29, 121, 196);
    }
  }
}

.device-sort-selected {
  div {
    position: absolute;
    background: #088fff;
    height: 100%;
    width: 9px;
    left: 0px;
    transition: all .2s;
    -webkit-transition: all .2s;
  }
  span {
    font-weight: bold;
    transition: all .2s;
    -webkit-transition: all .2s;
  }
}

#device-sort-by-list {
  li:last-child {
    border: none;
  }
  span, input {}
}

/* ----------------------------------------- RESTORE DEVICES NAV MENU */

#devices, #delete-devices {
  backface-visibility: hidden;
}

#devices {
  /* REMOVED BY BB: 11/4 */
  /* z-index: 2; */
  /* for firefox 31 */
  transform: rotateY(0deg);
  position: absolute;
}

#delete-devices {
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  position: absolute;
}

/* flip the pane when hovered */

.flipping {
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transition-duration: 5s;
  /* Safari */
  -moz-transition-duration: 5s;
  /* Safari */
  -ms-transition-duration: 5s;
  /* Safari */
  -o-transition-duration: 5s;
  /* Safari */
  transition-duration: 5s;
  /* Safari */
  transition-duration: 5s;
}

.flip {
  height: 100%;
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

#delete-devices .header-div {
  color: white;
  bottom: 22px;
  position: relative;
  border-bottom: 1px solid #0780e3;
  background: #0074d4;
  /* border-bottom: 1px solid rgba(7, 122, 217, 0.45); */
  width: 100%;
  height: 50px;
  text-align: center;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.36);
}

.deleted-device {
  color: white;
  height: 65px;
  border-bottom: 1px solid #0074d4;
  padding-left: 15px;
  cursor: pointer;
  &:hover {
    background: #0074d4;
  }
  &:last-child {
    border-bottom: none;
  }
}

.deleted-device-back-btn {
  font-size: 1.5rem;
  color: #ffffff;
  position: relative;
  left: 10px;
  top: 13px;
  cursor: pointer;
  &:hover {
    color: #73b2e7;
  }
}

#deleted-device-nav-list {
  position: relative;
  bottom: 25px;
}

#deleted-devices-header-title {
  position: relative;
  top: 13px;
  font-size: 1.5rem;
  left: 97px;
}

.restore-deleted-devices {
  display: block;
  width: 38px;
  position: absolute;
  left: 14px;
  top: 9px;
  cursor: pointer;
  color: white;
  font-size: 30px;
}

.deleted-device-name {
  font-size: 1.1rem;
}

.deleted-device-counter-container {
  background-color: rgb(244, 68, 63);
  opacity: 0.9;
  position: absolute;
  left: 10px;
  width: 17px;
  /* padding-bottom: 1px; */
  /* font-size: 1rem; */
  font-weight: bold;
  height: 17px;
  text-align: center;
  color: white;
  top: 11px;
  border-radius: 10px;
  /* box-shadow: 0px 1px 5px #d92229; */
}

.deleted-device-counter {
  font-size: .7rem;
  position: absolute;
  left: 5px;
  top: -2px;
}

#deleted-device-nav-list li {
  border-bottom: 1px solid #077ad9;
  color: white;
  padding-left: 32px;
  height: 74px;
}

.restore-device {
  font-size: .8rem;
  margin-right: 25px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    color: darkblue;
  }
}

/*.select-to-restore:hover{
    color: rgb(244, 208, 63);
}*/

.restore-all {
  cursor: pointer;
  position: relative;
  float: right;
  color: white;
}

#deleted-devices-container {
  width: 100%;
  overflow-y: auto;
  height: 175px;
  -webkit-box-shadow: inset 1px 1px 4px #4F4F4F;
  padding: 0px;
  background-color: rgba(18, 118, 209, 0.6);
}

.deleted-device-info {
  position: relative;
  top: -19px;
  left: 0px;
  font-size: .8rem;
}

.devices-to-restore-counter-container {
  background-color: rgb(244, 68, 63);
  opacity: 0.9;
  position: absolute;
  right: 22px;
  width: 13px;
  font-weight: bold;
  height: 13px;
  text-align: center;
  color: white;
  top: 6px;
  border-radius: 10px;
}

.devices-to-restore-counter {
  font-size: .6rem;
  position: absolute;
  left: 4px;
  top: -5px;
}

/* ------------------------------------ TASK AND CRON NAV MENU */

#cron {
  position: relative;
  overflow: auto;
  height: 100%;
}

#myTasks {
  overflow: auto;
  height: 500px;
  padding-bottom: 100px;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

/* line 84, sass/partials/_nav.scss */

.task-toggle {
  font-size: 1.3rem;
  cursor: pointer;
  margin-bottom: 20px;
}

#new-task-container, #current-task-container {
  font-size: 1.3rem;
  cursor: pointer;
  padding: 16px 17px 6px 17px;
  margin-top: 5px;
  clear: both;
}

#new-task-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.example {
  background: #0074d4;
  padding: 10px;
  box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.58);
}

/* line 91, sass/partials/_nav.scss */

.task-toggle span {
  float: right;
}

.tasks {
  color: #93ceff;
  font-size: 1rem;
}

/* line 97, sass/partials/_nav.scss */

#cron section {
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 20px;
  label {
    font-size: 16px;
    font-size: 1rem;
  }
  select, input {
    padding: 5px;
    margin-bottom: 10px;
  }
}

/* line 101, sass/partials/_nav.scss */

/* line 106, sass/partials/_nav.scss */

/*
.cron-time-hour, .cron-time-min{
	max-width: 20%;
}
*/
/* line 114, sass/partials/_nav.scss */

#myTasks li {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 10px;
  h3 {
    margin-bottom: 20px;
    font-family: "district_probook";
    em {
      font-size: 16px;
      font-size: 1rem;
      font-family: "district_prothin";
      color: rgba(255, 255, 255, 0.6);
    }
  }
  label {
    cursor: pointer;
    float: left;
    text-transform: uppercase;
    font-size: 14.4px;
    font-size: 0.9rem;
    span {
      float: left;
      margin-right: 10px;
      line-height: 28px;
    }
  }
  input {
    float: left;
    margin-top: 6px;
    width: 20px;
    margin-left: 20px;
    cursor: pointer;
  }
  button {
    font-size: 12.8px;
    font-size: 0.8rem;
    padding: 4px 10px;
    line-height: 1.5em;
    &.delete {
      color: red;
    }
  }
}

/* line 119, sass/partials/_nav.scss */

/* line 123, sass/partials/_nav.scss */

/* line 130, sass/partials/_nav.scss */

/* line 136, sass/partials/_nav.scss */

/* line 143, sass/partials/_nav.scss */

/* line 152, sass/partials/_nav.scss */

/* line 164, sass/partials/_nav.scss */

/* --------------------------------------------------------- CLOUD SYNC NAV MENU */

#sync {
  position: relative;
  height: 100%;
  .menu-header {
    font-size: 2.0rem;
    background: #4f4f4fd6;
    box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
    width: 100%;
    height: 50px;
    padding-left: 10px;
    padding-top: 7px;
  }
  .menu-title {
    font-size: 1.8rem !important;
  }
  .filter-container{
    margin-left: 10px;
    color: white;
  }
  .checkbox{
    color: white;
  }
}

#show-cloud-sync-readouts {
  margin: 30px 0px 0 14px;
  padding-left: 17px;
}

.approve-check {
  position: absolute;
  top: 13px;
}

#show-stale-unsaved label {
  font-size: 1rem;
  cursor: pointer;
}

#list-of-stale-unsaved-devices label {
  font-size: 1.1rem;
}

#show-stale-unsaved label {
  margin: 10px;
}

.up-to-date {
  font-size: 1rem;
  margin: 10px;
}

#unsaved-total-time {
  text-align: right;
  padding-right: 20px;
}

#unsaved-list>li, #list-of-stale-unsaved-devices>li {
  border-top: .5px solid #4f4f4fd6;
  margin-left: 0px !important;
  &:last-child{
    border-bottom:  .5px solid #4f4f4fd6;
  }
}

#unsaved-container {
  margin-bottom: 10px;
  overflow: auto;
  height: auto;
  top: 3px;
  position: relative;
  color:white;
  display:block;
  /* max-height: 60%; */
  max-height: 72%;
  color: white;
  padding: 10px;
  -webkit-overflow-scrolling: touch;
}

#stale-unsaved-container{
  max-height: 35%;
  display: block;
  position: relative;
  border-bottom: 1px solid #047ee5;
  border-top: 1px solid #047ee5;
  color:white;
  background-color: $nav-blue;
  title{
    height: 42px;
    padding: 11px;
    position: relative;
    display: inline-block;
  }

}

#show-stale-unsaved{
  color: white;
  background-color:$nav-blue;

}

#list-of-stale-unsaved-devices{
  max-height: 400px;
  font-size: 1rem !important;
  overflow-x: hidden;
  padding-bottom: 50px;
  overflow-y: auto;
  padding: 9px;
}


#unsaved-list label {
  font-size: 1.1rem !important;

}

#unsaved-list-label {
  font-size: 1.2rem;
  padding: 16px 8px 0px 6px;
  font-style: italic;
  margin:0;
}

#cron .menu-header {
  font-size: 1.8rem;
  background: #0074d49e;
  box-shadow: 1px 1px 3px rgba(37, 37, 37, 0.69);
  width: 100%;
  height: 50px;
  text-align: center;
  padding-top: 7px;
}


.unsaved-display {
  position: relative;
  right: 18px;
}

.saving-scene {
  margin-top: 15px;
}

/* ------------------------------------------------- CONTENT AREA CSS */

/* line 28, sass/partials/_pages.scss */

#content-area header #page-title {
  font-size: 48px;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 10px;
}

#settings-title {
  font-size: 48px;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  width: 40%;
}

header#scene-page-header {
  margin-bottom: 0px;
  max-width: 1200px;
  padding: 10px;
  position: relative;
}

#loc-settings-icon {
  z-index: 999;
}

#get-data-icon {
  z-index: 999;
  color: white;
}

.overview-info {
  color: #8eb8d9;
  list-style: none;
  margin-left: 10px;
}

#data-table tbody {
  box-shadow: 0px 0px 7px 2px black;
}

#get-data-icon-2 {
  position: relative;
  /* right: 46px; */
  /* top: 50px; */
  padding: 10px;
  font-size: 1.5rem;
  color: white;
  z-index: 999;
}

.devices-advanced-settings {
  font-size: 2.5rem;
  top: 145px;
  right: -11px;
  position: absolute;
  margin-right: 100px;
  width: 0px;
}

#content-area {
  .section-title-2-terminal {
    font-size: 16px;
    font-size: 1rem;
    text-align: Center;
    padding: 20px;
    margin-bottom: 0;
    margin-top: 10px;
    margin-left: 10%;
    width: 80%;
    background: #4f4f4f;
    letter-spacing: 1px;
    font-family: "district_prolight";
    -webkit-transition: background 0.15s ease-in-out;
    -moz-transition: background 0.15s ease-in-out;
    -ms-transition: background 0.15s ease-in-out;
    -o-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
  }
  .section-title span {
    float: right;
    display: none;
    /* until the controls are live */
  }
  .controls {
    background: white;
    background: rgba(255, 255, 255, 0.9);
    padding: 30px;
    display: none;
    .main-controls {
      width: 75%;
      float: left;
      .control {
        float: left;
        height: 100%;
        margin: 0 2%;
        text-align: center;
        .control-name {
          text-transform: uppercase;
        }
      }
    }
    .side-controls {
      width: 22%;
      float: right;
      text-transform: uppercase;
      .add-to-favorites {
        text-transform: uppercase;
        border-bottom: 1px solid #d6d8d9;
        padding-bottom: 20px;
        margin-bottom: 30px;
        color: #2d2d2d;
        font-size: 16px;
        font-size: 1rem;
        cursor: pointer;
        font-family: "district_prolight";
        i {
          font-size: 32px;
          font-size: 2rem;
          margin-right: 10px;
          color: #2eb8ff;
          vertical-align: middle;
        }
      }
      .control {
        clear: left;
        margin-bottom: 20px;
        line-height: 25px;
        .toggle-switch {
          float: left;
        }
        label.control-name {
          font-size: 16px;
          font-size: 1rem;
          color: #2d2d2d;
          margin-left: 60px;
          cursor: pointer;
        }
      }
    }
  }
}

/* line 92, sass/partials/_pages.scss */

/* line 98, sass/partials/_pages.scss */

/* line 103, sass/partials/_pages.scss */

/* line 107, sass/partials/_pages.scss */

/* line 113, sass/partials/_pages.scss */

/* line 119, sass/partials/_pages.scss */

/* line 124, sass/partials/_pages.scss */

/* line 134, sass/partials/_pages.scss */

/* line 142, sass/partials/_pages.scss */

/* line 147, sass/partials/_pages.scss */

/* line 152, sass/partials/_pages.scss */

/* line 164, sass/partials/_pages.scss */

.slider-vertical {
  width: 10px;
  background: #d6d8d9;
  border: none;
  height: 230px;
  margin: 20px auto;
  .ui-slider-handle {
    left: -5px;
    margin-bottom: -5px;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: none;
    background: #0074d4;
  }
  .ui-widget-header {
    background: #3ba6ff;
  }
}

/* line 171, sass/partials/_pages.scss */

/* line 181, sass/partials/_pages.scss */

/* line 4, sass/partials/_forms.scss */

input, textarea, select, button {
  width: 95%;
  display: block;
  font-family: "district_prolight";
  color: #2d2d2d;
  font-size: 16px;
  font-size: 1rem;
  padding: 3px 5px;
  border: none;
  margin: 0;
  margin-bottom: 20px;
}

.reset-pass {
  position: relative;
  cursor: pointer;
  width: 100px;
}

.small-input {
  width: 15%;
  padding: 0px;
  margin-bottom: 5px;
  margin-left: 15px;
}

.inline-small-input {
  width: 15%;
  padding: 0px;
  /*     margin-bottom: 5px; */
  /*     margin-left: 15px; */
}

/* line 16, sass/partials/_forms.scss */

label {
  display: block;
  color: white;
  font-size: 19.2px;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

button {
  border-radius: 3px;
  color: white;
  margin: 0px;
  cursor: pointer;
  line-height: 1;
  width: auto;
  padding: 5px;
  background: $primary-button-color;
}

/* line 35, sass/partials/_forms.scss */

.orange input[type="submit"] {
  color: #d1952b;
}

/* line 40, sass/partials/_forms.scss */

form {
  margin-bottom: 40px;
}

/* MODERNIZR CLASSES USED FOR FEATURE DECTECTION */
/* IE 9 and below */
/* line 5, sass/partials/_ie.scss */

.no-flexbox {
  /* IE 9 only*/
  /* IE 8 and below */
  &.no-canvas {
    /* IE 8 only */
    /* IE 7 and below */
  }
}

/* line 16, sass/partials/_ie.scss */

/* ------------------------------------------------ LOCATIONS CONTENT REA */

.loc-name {
  padding: 5px;
  outline: none;
  top: 3px;
}

.loc-name-list, .device-name, .loc-name, .scene-name {
  cursor: pointer;
  font-size: 16px;
}

.can-edit {
  cursor: text;
}
.device-li > .list-item-content{
  text-align: left;
  float: left;
}

.device-li > .list-item-content > .can-edit, .add-device-li {
  cursor: pointer;
}

.location-info li {
  vertical-align: middle;
  clear: both;
}

.location-info-toggle {
  right: 20px;
  margin-bottom: 10px;
}

/*Advanced device options dropdown stuff*/

devices-advanced-settings {
  font-size: 2.0em;
  position: relative;
  position: absolute;
  /* float: right; */
  z-index: 999;
  top: 250px;
  right: 87px;
  /* left: 91%; */
  width: 0px;
  cursor: pointer;
}

/* Make it easy to press */

.devices-advanced-settings i {
  padding: 10px 0px 10px 5px;
  color: white;
  cursor: pointer;
}

#advanced-settings-icon.fa-close {
  background-color: #3e3e3e;
  border-radius: 5px;
  z-index: 5;
}

/* The style for the whole dropdown */

.advanced-settings-dropdown {
  background-color: rgba(62, 62, 62, 1);
  color: white;
  width: 245px;
  height: 70vh;
  max-height: 548px;
  font-size: 14px;
  display: none;
  z-index: 9998;
  position: absolute;
  top: 52px;
  right: -1px;
  border-radius: 2%;
  cursor: pointer;
  overflow: auto;
  vertical-align: middle;
  box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.advanced-device-menu-icon {
  margin-right: 10px;
}

/* Items not at the bottom need a morder */

.advanced-settings-dropdown {
  .bottom-border {
    border-bottom: 1px solid rgba(98, 98, 98, 1);
  }
  li {
    /*     height:40px; */
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      /*     height:40px; */
      background-color: rgba(104, 104, 104, 1);
    }
  }
}

/* Restricts the heigh of the list items */

.advanced-settings-dropdown-string {
  margin: 0% 0% 0% 2%;
  padding: 0% 3% 0% 4%;
}

/*end advanced dropdown menu stuff*/

/* ------------ DEVICE GROUP STUFF */

#device-groups-list li i:hover {
  color: red;
}

.delete-device-group-icon {
  float: right;
  margin-right: 15px;
  margin-top: 15px;
  &:hover {
    color: red;
  }
}

.device-group-list-item {
  color: white;
  border-radius: 4px;
  margin-left: 8px;
  margin-top: 8px;
  background-color: rgba(64, 64, 64, 1);
  box-shadow: inset 0px 0px 5px 2px rgba(39, 39, 39, 1);
  width: 165px;
  text-size: 10%;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.modal-device-group-list-item {
  color: white;
  border-radius: 4px;
  margin-left: 5px;
  margin-top: 5px;
  background-color: rgba(59, 59, 59, 0.9);
  box-shadow: inset 0px 0px 5px 2px rgba(39, 39, 39, 1);
  width: 165px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.group-name-display {
  margin-left: 15px;
  cursor: pointer;
  padding-right: 25px;
}

#group-modal-main-content {
  position: relative;
}

#current-modal-group-display {
  margin-bottom: 10px;
}

.group-select-option {
  color: white;
  height: 40px;
  border-bottom: 1px solid rgba(89, 89, 89, 1);
}

#group-name-container {
  margin-top: 10px;
  background-color: rgba(59, 59, 59, 0.9);
  border-radius: 1%;
  height: 260px;
  box-shadow: inset 0px 0px 10px 5px rgba(39, 39, 39, 1);
}

#new-group-name-input {
  width: 60%;
  border: 4px solid rgba(49, 49, 49, 0.9);
  margin-top: 10px;
  display: inline-block;
  margin-bottom: 2%;
  border-radius: 5px;
}

#submit-new-group {
  position: absolute;
  top: 20px;
  right: 40px;
  color: white;
  font-size: 27px;
  display: inline-block;
  float: right;
  z-index: 50;
  cursor: pointer;
}

.add-group-stuff {
  display: none;
}

#group-search-x {
  right: 30px;
  top: 38px;
  position: absolute;
}

.group-option-delete-button {
  cursor: pointer;
  margin-right: 30px;
  margin-top: 10px;
  text-size: 30px;
  float: right;
  z-index: 10;
  &:hover {
    color: red;
  }
}

#group-list-search-bar {
  width: 100%;
  border: 4px solid rgba(49, 49, 49, 0.9);
  display: inline-block;
  margin-bottom: 2%;
  border-radius: 5px;
}


.delete-group-icon {
  float: right;
  margin-right: 10px;
  padding: 3px;
}

.new-group-button:hover {
  background-color: rgba(28, 146, 251, 1);
}

/*------------------END OF DEVICE GROUP STUFF*/

#device-ul {
  max-height: 60%;
}

.devices-for {
  margin-bottom: 10px;
  margin-left: 15px;
  top: 6px;
  font-size: 20px;
  display: inline;
  position: absolute;
}

.menu-title-devices {
  top: 35px;
  text-align: left;
  display: inline;
  position: absolute;
  text-align: left;
  float: left;
}

.menu-title, .menu-title-loc {
  display: inline;
}

/*------------------------------------NEW DEVICE MODAL*/

#new-device-modal .modal-content, #assoc-phd-modal .modal-content {
  background-color: rgba(49, 49, 49, 0.9);
  color: white;
}

#new-device-modal .modal-footer, #assoc-phd-modal .modal-footer {
  border-color: rgba(105, 105, 105, 0.9);
  margin-top: 10px;
}

#new-device-modal {
  .modal-content .modal-header h4.modal-title {
    font-weight: 500;
  }
  .modal-header {
    border-color: rgba(105, 105, 105, 0.9);
  }
}

#assoc-phd-modal .modal-header {
  border-color: rgba(105, 105, 105, 0.9);
}

#new-device-modal .btn-default {
  color: white;
  border-color: rgba(80, 80, 80, 0.9);
  background-color: rgba(49, 49, 49, 0.9);
}

.new-device-list {
  background-color: rgba(49, 49, 49, 0.9);
  padding: 1%;
  border-radius: 2px;
  max-height: 400px;
  overflow: auto;
}

.new-device-ul {
  -webkit-overflow-scrolling: touch;
  background-color: rgba(49, 49, 49, 0.9);
  border-radius: 2px;
}

.new-device-check {
  margin-right: 20px;
}

.new-device-li {
  padding-left: 15px;
  border-bottom: 1px rgba(80, 80, 80, 0.9) solid;
}

.fa-check {
  color: white;
}

.unselected {
  background-color: darkgrey;
}

.new-device-linebreak {
  padding: 0px;
}

/*new device end*/

/*----------------------------------END OF DEVICES */

#myModal {
  .modal-content {
    background-color: rgba(89, 89, 89, 1);
    color: white;
  }
  .modal-footer, .modal-header {
    border-color: rgba(49, 49, 49, 0.9);
  }
}

.close {
  color: white;
}

#myModal .btn-default {
  color: white;
  border-color: rgba(49, 49, 49, 0.9);
  background-color: rgba(49, 49, 49, 0.9);
}

#edit-page {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4c4c4c+0,595959+12,666666+25,474747+39,2c2c2c+50,000000+51,2b2b2b+76,1c1c1c+91,131313+100 */
  background: #4c4c4c;
  /* Old browsers */
  background: -moz-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4c4c4c 0%, #595959 12%, #666666 25%, #474747 39%, #2c2c2c 50%, #000000 51%, #2b2b2b 76%, #1c1c1c 91%, #131313 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313',GradientType=0 );
  /* IE6-9 */
}

.locations-features-dropdown-string{
  text-align: left;
  padding-left: 5px;
}
select#loc-template-options {
  left: 3px;
  top: 10px;
  position: relative;
}

.loc-features-options {
  color: white;
  float: right;
  font-size: 2.0rem;
  position: relative;
  top: 10px;
  right: 12px;
  z-index: 20;
  cursor: pointer;
  i {
    padding: 10px 0px 10px 5px;
  }
}

.loc-features-dropdown {
  background-color: rgba(62, 62, 62, 1);
  color: white;
  width: 145px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: -5px;
  padding: 5px;
  top: 28px;
  z-index: 10;
  border-radius: 2%;
  vertical-align: middle;
  box-shadow: -2px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  .bottom-border {
    border-bottom: 1px solid rgba(98, 98, 98, 1);
  }
}

.loc-list-settings-dropdown li {
  max-height: 40px;
  cursor: pointer;
}



.loc-list-settings-dropdown .bottom-border {
  border-bottom: 1px solid rgba(98, 98, 98, 1);
}

/* TODO: Hover isnt working... */
/*
.loc-list-settings-dropdown li:hover {
	background-color: #737573;
}
*/

.subtitle-dropdown {
  z-index: 999;
  position: absolute;
  padding: 10px;
  top: 8px;
  right: 14px;
  cursor: pointer;
  font-size: 24px;
  font-size: 1.7rem;
  color: white;
}

#psm-config-list li {
  height: 60px;
  border-bottom: 1px solid rgb(45, 45, 45);
}

.psm-check-all-button {
  padding: 10px 10px 0px 0px;
  float: right;
  margin-right: 25%;
  display: inline;
  label {
    color: #23c85f;
    float: right;
    margin-left: 25px;
  }
}

#psm-check-all {
  /*     float:left; */
  padding: 10px;
}

.psmConfig {
  padding: 10px 0px 0px 0px;
  border-bottom: 2px solid black;
  /*     text-align: justify; */
  /*     -ms-text-justify: distribute-all-lines; */
}

.psmChoices {
  width: 20%;
  /*     vertical-align: top; */
  /*     display: inline-block; */
  font-weight: 700;
}

.psmConfigLabel {
  width: 20%;
  /*     vertical-align: top; */
  /*     display: inline-block; */
  font-weight: 700;
  margin-top: 8px;
  float: left;
  text-align: left;
  margin-left: 30%;
}

.psmChoices {
  float: right;
  margin-top: 8px;
  line-height: 1;
  height: 20px;
  margin-right: 30%;
}

.psmON {
  float: right;
  display: inline-block;
  font-weight: 900;
}

.psmOFF {
  float: right;
  display: inline-block;
  font-weight: 900;
  margin-left: 15px;
  margin-right: 30px;
}

.psmON {
  margin-left: 20px;
  input {
    -webkit-appearance: checkbox;
    border-radius: 3px;
    margin-top: 5px;
  }
}

.psmOFF input {
  -webkit-appearance: checkbox;
  border-radius: 3px;
  margin-top: 5px;
}

.stretch {
  width: 100%;
  display: inline-block;
  line-height: 0;
}

.ping-device {
  position: relative;
  color: black;
  left: 21px;
  top: 3px;
}

.neg-top-marg {
  position: relative;
  top: -21px;
}

.no-scroll-header {
  position: relative;
  top: 0px;
  padding: 9px 0px 0px 0px;
  z-index: 999;
  background: #078fff;
  width: 100%;
  max-width: 400px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 160px;
  border-bottom: 1px solid rgba(18, 118, 209, 1);
}

.no-scroll-header-grey {
  position: relative;
  top: 0px;
  padding: 9px 0px 0px 0px;
  z-index: 999;
  background: #078fff;
  width: 100%;
  max-width: 400px;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 160px;
  border-bottom: 1px solid rgba(18, 118, 209, 1);
  top: 0px;
  padding: 9px 0px 0px 0px;
  background: rgba(105, 105, 105, 1);
  max-width: 400px;
  margin-right: 20px;
  height: 130px;
  border-bottom: none;
}

.scrollable-content {
  position: fixed;
  top: 175px;
  overflow: auto;
}

.icon-label {
  font-size: .57rem;
  font-weight: 600;
  position: absolute;
  clear: both;
  letter-spacing: 0.5px;
}

.icon-label-small {
  font-size: .56rem;
  font-weight: 600;
  position: absolute;
  clear: both;
  letter-spacing: 0.5px;
}

.all-features-label {
  width: 100%;
  /* text-align: left; */
  /* REMOVED BY B 9/20 was breaking on mobile */
  /* position: absolute; */
  font-size: 1.8rem;
  padding: 0px 0px 10px 0px;
}

.template-options {
  width: 200px;
}

.features-label {
  font-size: 1.8rem;
}

/*
.feature-label{
    margin:0px 20px 0px 0px;
    width:100%;
    display:block;
}
*/

#function-buttons {
  margin-top: 20px;
  width: 100%;
  font-size: 2rem;
  color: white;
  i {
    padding: 20px;
  }
}

.main-list-header {
  text-align: center;
  margin-top: 15px;
}

.active-loc {
  color: #86adcc;
}

.ident-button, .reboot-button, .ping-button {
  float: left;
}

.scene-id {
  position: relative;
  font-size: 100%;
  top: 0px;
  display: inline-block;
  padding-left: 0;
}

.scene-action {
  width: 90%;
  /*     float:left; */
  text-align: left;
  /*     padding:3px 0px 0px 38px; */
  margin: 3px 0px 10px 40px;
  /*     border-bottom: 1px solid #414040; */
}

.delete-scene-action {
  padding: 0px 15px;
  cursor: pointer;
}

#showall {
  cursor: pointer;
}

.no-outline, .device-name {
  outline: none;
}

.editable-text {
  background: #a1a1a1;
  box-shadow: inset 0px 0px 4px black;
  cursor: text;
  min-width: 40px;
  max-height: 55px;
  margin-left: 0px;
  border-radius: 2px;
  padding: 5px 0px 5px 0px;
  color: white !important;
}

.scene-label {
  font-size: 30px;
  font-size: 2rem;
  /*   text-transform: uppercase; */
  margin-bottom: 10px;
}

.scene-device-label {
  width: 100%;
  text-align: left;
  display: inline;
  font-size: 16px !important;
}

.clear {
  clear: both;
}

#scene-name {
  padding: 5px;
  outline: none;
}

.action-device-icon {
  margin-right: 7px;
}

.add-action, .add-trigger {
  position: absolute;
  top: 6px;
  right: 0px;
  padding: 5px 20px;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all .2s;
  -webkit-transition: all .2s;
}

.button-badge {
  position: relative;
  top: -17px;
  left: 11px;
  border-radius: 50%;
  color: #f04848;
  font-size: 1rem;
  z-index: 999;
  /*     height:16px; */
  height: 8px;
  width: 8px;
  background-color: #f04848;
}

.button-badge-device {
  font-size: 1rem;
  position: absolute;
  right: 2px;
  margin-top: 5px;
  color: #f04848;
}

/* line 24, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/ */

ol, ul {
  list-style: none;
  display: block;
  line-height: 275%;
}

.main-device-rssi {
  cursor: pointer;
}

#no-scenes-label {
  position: relative;
  top: 40px;
  height: 70px;
}

.scene-list-button {
  position: relative;
  height: 51px;
}

.active-main-nav {
  /*background: #007ce0; */
}

ul li div {
  vertical-align: middle;
}

.settings-input {
  z-index: 999;
}

/*.lock-button-scene {
    width: 50px;
    /* padding: 15px 11px 0px 10px;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 12px;
    position: relative;
    left: 40px;
    top: 3px;
}*/

/* line 26, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* line 28, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

caption, th, td {
  text-align: center;
  vertical-align: middle;
}

/* line 30, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

q, blockquote {
  quotes: none;
}

/* line 103, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

q {
  &:before, &:after {
    content: "";
    content: none;
  }
}

blockquote {
  &:before, &:after {
    content: "";
    content: none;
  }
}

/* line 32, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

a img {
  border: none;
}

/* line 116, ../../../Ruby200-x64/lib/ruby/gems/2.0.0/gems/compass-0.12.2/frameworks/compass/stylesheets/compass/reset/_utilities.scss */

article, aside, details, figcaption, figure, footer, header, menu, nav, section, summary {
  display: block;
}

/* FONT */
/* COLORS */
/* GRID SYSTEM */
/* BREAK TO MOBILE */
/* Functions */
/* FONT SIZE REM */
/* BACKGROUND RGBA */
/* BACKGROUND HEX TO RGBA */
/* DISPLAY INLINE-BLOCK FOR ALL! */
/* BOX SIZING */
/* line 1, sass/partials/_type.scss */

body {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4em;
  top: 0px;
  font-family: "district_prolight", Helvetica, Arial, sans-serif;
  color: #4f4f4f;
  left: 0px;
  position: fixed;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#page-wrapper {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.4em;
  top: 0px;
  font-family: "district_prolight", Helvetica, Arial, sans-serif;
  color: #4f4f4f;
  left: 0px;
  position: fixed;
  /* WE MODIFY OVERFLOW-Y LOCATION FEATURES GETS DROPPED DOWN - IF YOU DONT EXPLICITELY SET OVERFLOW-Y HIDDEN IN SAFARI Itll scroll */
  overflow-y: hidden;
  overflow-x: hidden;
  /* set to one lower than the nav so it sits below it but above anything else it might need to site above */
  z-index: 49;
  /* -webkit-overflow-scrolling: touch; */
  /*background: #2d2d2d;*/
  background: linear-gradient(#313335 45%, #17191a);
  height: 100vh;
  width: 100vw;
}

.knob-readout {
  position: relative;
}

/* line 13, sass/partials/_type.scss */

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-family: "district_prothin", Helvetica, Arial, sans-serif;
  line-height: 1em;
  margin-bottom: 0.4em;
  color: white;
}

/* line 21, sass/partials/_type.scss */

h1 {
  font-size: 32px;
  font-size: 2rem;
  /*   margin-bottom: 0.8em; */
}

/* line 29, sass/partials/_type.scss */

h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

/* line 36, sass/partials/_type.scss */

h3 {
  font-size: 20.8px;
  font-size: 1.3rem;
}

/* line 43, sass/partials/_type.scss */

h4 {
  font-size: 19.2px;
  font-size: 1.2rem;
}

/* line 50, sass/partials/_type.scss */

h5, h6 {
  font-size: 16px;
  font-size: 1rem;
}

/* global box-sizing */

/* line 3, sass/partials/_grid.scss */

.col4grid {
  width: 23%;
  float: left;
  margin-bottom: 2%;
  img {
    width: 100%;
  }
}

/* line 123, sass/partials/_grid.scss */

/* line 130, sass/partials/_grid.scss */

.col3grid {
  width: 32%;
  float: left;
  margin-bottom: 2%;
  &.mid-col {
    margin-left: 2%;
    margin-right: 2%;
  }
  &.first-col {
    clear: left;
  }
}

/* line 146, sass/partials/_grid.scss */

/* line 156, sass/partials/_grid.scss */

/* line 172, sass/partials/_grid.scss */

.col2grid {
  width: 49%;
  float: left;
  margin-bottom: 2%;
  margin-left: 2%;
  &.two-col-clear {
    clear: left;
    margin-left: 0;
  }
}

/* line 178, sass/partials/_grid.scss */

/**
 * onoff.css
 * Author: http://proto.io/freebies/onoff/
 * Author: Timmy Willison
 */
/* line 18, sass/partials/_onoff.scss */

.onoffswitch {
  position: relative;
  width: 50px;
  user-select: none;
  float: left;
}

/* line 24, sass/partials/_onoff.scss */

.onoffswitch-checkbox {
  display: none;
}

/* line 28, sass/partials/_onoff.scss */

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0px solid #999999;
  border-radius: 20px;
}

/* line 35, sass/partials/_onoff.scss */

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.1s ease-in;
  &:before, &:after {
    float: left;
    padding: 0;
    width: 50px;
    height: 26px;
    color: white;
    font: bold 14px/26px Trebuchet, Arial, sans-serif;
    box-sizing: border-box;
  }
  &:before {
    content: "";
    padding-left: 10px;
    color: #FFFFFF;
    background-color: #0074d4;
  }
  &:after {
    content: "";
    padding-right: 10px;
    color: #999999;
    background-color: #1d1d1d;
    text-align: right;
  }
}

/* line 41, sass/partials/_onoff.scss */

/* line 49, sass/partials/_onoff.scss */

/* line 56, sass/partials/_onoff.scss */

/* line 64, sass/partials/_onoff.scss */

.onoffswitch-switch {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 24px;
  width: 24px;
  height: 24px;
  margin: 1px;
  background: white;
  border: 0px solid #999999;
  border-radius: 20px;
  transition: right 0.1s ease-in, box-shadow 0.1s ease-in;
}

/* line 79, sass/partials/_onoff.scss */

.onoffswitch-checkbox:checked + .onoffswitch-label {
  .onoffswitch-inner {
    margin-left: 0;
  }
  .onoffswitch-switch {
    right: 0;
  }
}

/* line 82, sass/partials/_onoff.scss */

/* line 1, sass/partials/_layout.scss */

body {
  background: #202020;
  /* B - too look good native iphone  */
  -webkit-overflow-scrolling: touch;
  #action-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: black;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9000000;
  }
}

/* line 4, sass/partials/_layout.scss */

/* line 15, sass/partials/_layout.scss */

.add-to-favorites-header {
  display: none;
  color: #d6d8d9;
  background: #1c1c1c;
  position: absolute;
  padding: 10px 15px;
  top: 0;
  right: 0;
  cursor: pointer;
  i {
    font-size: 19.2px;
    font-size: 1.2rem;
    margin-right: 10px;
    color: #f9ff5a;
  }
}

/* line 25, sass/partials/_layout.scss */

/* line 32, sass/partials/_layout.scss */

.button {
  font-size: 16px;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  background: #4f4f4f;
  padding: 20px;
  color: white;
  display: block;
  margin-top: 35px;
  letter-spacing: 1px;
  -webkit-transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -ms-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
}

.button-scene {
  background: #5c5c5c;
  padding: 10px;
  margin: 5px;
  position: relative;
  z-index: 999;
  /* width: 95%; */
  cursor: pointer;
}

.ll-slider, .maxmin-level-slider, .occ-level-slider {
  cursor: pointer;
}

/* line 48, sass/partials/_layout.scss */

.button:hover {
  background: #5c5c5c;
}

.button-scene:hover {
  background: #989797;
}

/* line 172, sass/partials/_nav.scss */

nav section {
  margin: 40px 0;
  .section-title {
    font-size: 22.4px;
    font-size: 1.4rem;
    padding-bottom: 10px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

/* line 175, sass/partials/_nav.scss */

/* line 185, sass/partials/_nav.scss */

.mm-menu .mm-list {
  padding: 0;
  > li {
    margin-left: 10px;
    a, span {
      font-size: 19.2px;
      font-size: 1.2rem;
      color: white;
    }
    &:not(.mm-subtitle):not(.mm-label):not(.mm-noresults):after {
      margin-left: 0px;
    }
  }
  .mm-subtitle .mm-subclose {
    font-family: "district_prothin";
    font-size: 32px;
    font-size: 2rem;
    color: white;
    color: rgba(255, 255, 255, 0.6);
    line-height: 2.5rem;
    background: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
}

/* line 188, sass/partials/_nav.scss */

/* line 192, sass/partials/_nav.scss */

/* line 198, sass/partials/_nav.scss */

/* line 203, sass/partials/_nav.scss */

/* line 217, sass/partials/_nav.scss */

.mm-panel .close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 19.2px;
  font-size: 1.2rem;
  color: white;
  color: rgba(255, 255, 255, 0.6);
}

/* line 230, sass/partials/_nav.scss */

html.mm-opened .mm-page {
  box-shadow: none;
}

.ident-device {
  cursor: pointer;
}

.dp-info {
  padding-left: 10px;
  padding: 10px;
  font-size: 0.6rem;
  top: 0;
  right: 0;
  color: grey;
  cursor: pointer;
}

.device-data-settings-title > .dp-info {
  position: absolute;
}

/*

#page-content{
    height:100%;
    overflow:auto;
    -webkit-overflow-scrolling: touch;
}
*/

/* line 1, sass/partials/_pages.scss */

.settings-checkbox {
  height: 80px;
  padding: 0;
  margin: 0;
  vertical-align: middle;
}

.setting-label::selection {
  background: rgba(0, 0, 0, 0);
}

.fr-up, .fr-down {
  padding: 10px 5px;
  cursor: pointer;
}


#msie-error {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: none;
  background-color: gray;
  vertical-align: top;
  top: 0;
  left: 0;
}

#msie-error-text {
  vertical-align: middle;
  text-align: center;
  font-size: 200px;
  font-weight: bold;
}

.device-scroll-container {
  height: 60%;
}

.scroll-container {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 65%;
  /* TODO:This isnt how we should be keeping this from running over the device list */
  -webkit-box-shadow: inset 1px 1px 4px #4F4F4F;
  background-color: rgba(18, 118, 209, 0.6);
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: rgba(54, 52, 54, 0.63);
}

.new-device-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.search-bar-x {
  color: grey;
  position: absolute;
  /*     right:35px; */
  /*     top:140px; */
  display: none;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

#add-to-groups-header {
  line-height: 200%;
  /* display: none; */
  position: relative;
}

.group-select-list-item {
  color: white;
  cursor: pointer;
  position: relative;
  height: 45px;
  padding-left: 11px;
  border-bottom: 1px solid rgba(28, 146, 251, 1);
  width: 100%;
}

.device-selected-for-group {
  background-color: rgba(18, 118, 209, 0.6);
}

#group-select-list .border-bottom {
  width: 100%;
  border-bottom: 1px solid rgba(28, 146, 251, 1);
}

#tag-groups-search-bar {
  width: 68%;
  margin-top: 5px;
  margin-left: 0px;
  border-radius: 5px;
  /*display: none;*/
  margin-bottom: 12px;
  padding: 2px 15px;
  border: 4px solid white;
}

#tag-groups-search-x {
  /* top: 84px; */
  /* right: 26px; */
  right: 21px;
  padding: 10px;
  cursor: pointer;
  top: 50px;
  /* position: relative; */
}

#tag-groups-container {
  /*display: none;*/
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgba(18, 118, 209, 0.6);
  border-radius: 1%;
  height: 136px;
  width: 100%;
}

.groups-add-not-root-loc {
  top: 16%;
}

input#site-email, #site-pass {
  height: 50px;
}

#add-to-groups-container, #recently-deleted-devices-container {
  padding: 9px 19px 0px 19px;
  width: 100%;
  position: relative;
  height: 100%;
}

.device-options-shown {
  height: 300px !important;
  transition: all .2s;
}

.group-selected {
  background-color: rgba(28, 146, 251, 1);
  box-shadow: inset -8px 0px 12px -10px black, inset 14px 0px 8px -14px black;
  &:first-child {
    box-shadow: inset -8px 7px 12px -10px black, inset 14px 0px 8px -14px black;
  }
  &:last-child {
    box-shadow: inset -8px -6px 12px -10px black, inset 14px 0px 8px -14px black;
  }
}

#device-search-bar-container {
  width: 100%;
  top: 32%;
  margin: 0.6em 0;
  border-radius: 1%;
}

#no-devices-add {
  box-shadow: -2px 0px 12px -3px black;
  height: 100%;
  position: relative;
}

.loc-list-ul {
  padding-top: 7px;
  -webkit-overflow-scrolling: touch;
}

.noMatch, .noDevices {
  color: white;
  padding: 10px;
}

.add-to-groups-open {
  top: 87% !important;
}

#add-remove-groups-button-container {
  /* display: none; */
  right: 60%;
  top: 14px;
  position: relative;
  label {
    font-size: 1.1rem;
  }
}

#tag-device-dropdown-container label a, #tag-device-dropdown li a {
  text-decoration: none;
}

#tagged-device-search-x {
  left: 88%;
  padding: 10px;
  cursor: pointer;
  top: 16px;
}

#tagged-device-search-x-root {
  right: 12px;
  padding: 10px;
  cursor: pointer;
  top: 0px;
}

#device-search-x {
  left: 88%;
  top: 1%;
  padding: 10px;
  cursor: pointer;
}

#device-list-bottom-spacer {
  position: relative;
  height: 30px !important;
}

#device-name-container {
  margin-top: 10px;
  background-color: rgba(18, 118, 209, 0.6);
  border-radius: 1%;
  height: 260px;
}

#locations-dropdown-container {
  position: absolute;
  width: 40%;
  background: rgba(18, 118, 209, 0.6);
  vertical-align: middle;
  border-radius: 2%;
  box-shadow: 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.6s ease;
}

#scenes-dropdown-container {
  position: absolute;
  width: 20%;
  background: rgba(18, 118, 209, 0.6);
  vertical-align: middle;
  border-radius: 2%;
  box-shadow: 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.6s ease;
}

.no-devices {
  position: absolute;
  top: 60%;
  left: 26%;
}

#tag-device-dropdown-container {
  position: absolute;
  width: 40%;
  background: rgba(18, 118, 209, 0.6);
  vertical-align: middle;
  border-radius: 2%;
  box-shadow: 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  outline: none;
  -webkit-transition: all 0.6s ease;
  z-index: 9999;
}

#tag-device-dropdown {
  background-color: rgba(18, 118, 209, 1);
  color: white;
  width: 100%;
  display: none;
  /*
  	border-bottom: 1px solid blac
  	border-left: 1px solid black;
  */
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  vertical-align: middle;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  /* 	-webkit-transition-property: ; */
  -webkit-transition: background 0.15s ease;
  -moz-transition: background 0.15s ease;
  -ms-transition: background 0.15s ease;
  -o-transition: background 0.15s ease;
  transition: background 0.15s ease;
}

.loc-settings-dropdown {
  background-color: rgba(18, 118, 209, 1);
  color: white;
  width: 150px;
  height: 200px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 1px;
  z-index: 10;
  /*
  	border-bottom: 1px solid black;
  	border-left: 1px solid black;
  */
  border-radius: 2%;
  vertical-align: middle;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  /* 	-webkit-transition-property: ; */
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

#scenes-dropdown {
  background-color: rgba(18, 118, 209, 1);
  color: white;
  width: 100%;
  display: none;
  /*
  	border-bottom: 1px solid black;
  	border-left: 1px solid black;
  */
  border-bottom-left-radius: 2%;
  border-bottom-right-radius: 2%;
  vertical-align: middle;
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  /* 	-webkit-transition-property: ; */
  -webkit-transition: background 0.15s ease;
  -moz-transition: background 0.15s ease;
  -ms-transition: background 0.15s ease;
  -o-transition: background 0.15s ease;
  transition: background 0.15s ease;
}

.tag-device-dropdown.changed {
  background-color: rgba(18, 118, 209, 0.6);
}

.current-dropdown-item a {
  position: relative;
  top: 4px;
  left: 2%;
  vertical-align: middle;
  text-align: center;
  color: white;
  float: left;
  padding: 2% 0% 0% 4%;
}

.dropdown-arrow {
  font-family: FontAwesome;
  color: white;
  float: right;
  padding: 3% 4% 0% 0%;
}

.dropdown-string {
  margin: 0% 0% 0% 7%;
  padding: 0% 3% 0% 4%;
}

#tag-device-dropdown .bottom-border {
  border-bottom: 1px solid rgba(90, 162, 252, 1);
}

.loc-list-settings-dropdown {
  background-color: rgba(62, 62, 62, 1);
  color: white;
  width: 150px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 48px;
  top: 43px;
  z-index: 10;
  border-radius: 2px;
  vertical-align: middle;
  box-shadow: -3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  li {
    max-height: 40px;
    cursor: pointer;
  }
}

.loc-list-dropdown-string {
  margin: 0% 0% 0% 7%;
  padding: 0% 3% 0% 4%;
}

.loc-list-settings-dropdown .bottom-border {
  border-bottom: 1px solid rgba(98, 98, 98, 1);
}

.locations-dropdown-string:hover {
  background-color: rgba(28, 146, 251, 1);
}

.locations-list-dropdown-string {
  padding: 0% 0% 0% 8%;
  border-bottom: 1px solid rgba(28, 146, 251, 1);
  &:hover {
    background-color: rgba(28, 146, 251, 1);
  }
}

.dropdown-string .locations-dropdown-string a:visited {
  color: #E8E8E8;
}

.current-dropdown-item a:visited {
  color: white;
}

.dropdown-active {
  display: none;
}

.tiny-item {
  margin-bottom: 5px;
}

#button-container {
  margin-top: 1%;
}

.tagged-device-list li {
  position: relative;
}

.device-list-button {
  &:hover, &:focus {
    color: white;
    text-decoration: none;
  }
}

.centering-block {
  height: 100%;
  float: left;
  font-size: 1.5rem;
  color: white;
  /* left: 90%; */
  /* top: -4px; */
  width: 13%;
  padding-top: 11px;
  position: relative;
  cursor: pointer;
}

.group-tag-root {
  left: 73% !important;
  top: 5% !important;
}

#group-devices-selector li {
  display: inline-block;
  margin: 0% 0% 0% 2%;
  padding: 0% 3% 0% 4%;
  color: white;
  background-color: rgba(62, 62, 62, 1);
  width: 20px !important;
  height: 40px;
  cursor: pointer;
}

.modal-text {
  line-height: 20px;
  padding: 10px 10px 0 0;
  margin: 30px 30px;
}

.associated-scenes-list {
  cursor: pointer;
  color: white;
  &:hover {
    color: rgba(98, 98, 98, 1);
  }
}

/*alert modal stuff*/

#alert-modal {
  .modal-title {
    position: relative;
    text-align: center;
    color: white;
    padding-bottom: 5px;
  }
  .modal-alert-text {
    padding: 10px 10px 10px 10px;
  }
  .modal-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom: none;
    padding: 3px 15px 0 15px;
    color: white;
    font-size: 30px;
    margin: 0;
  }
  .error-header {
    background-color: #d03939;
  }
  .success-header {
    background-color: rgba(25, 130, 87, 1);
  }
  .alert-header {
    background-color: rgb(244, 208, 63);
  }
  .info-header {
    background-color: #088fff;
  }
  .modal-text-box {
    border-radius: 1%;
    position: relative;
    height: auto;
    width: 100%;
    background-color: white;
    text-align: center;
    line-height: 40px;
    margin: 0;
    color: black;
  }
  .modal-content {
    border-radius: 5px;
    width: 85%;
    margin: 30% auto;
  }
  .modal-text {
    padding: 10px 10px 10px 10px;
  }
}

.alert-btn-close {
  margin-right: 15px;
}

.alert-modal-body {
  padding: 0;
}

#alert-modal {
  .button-left {
    margin-right: 20px;
  }
  .modal-text-box {
    margin: 0px;
  }
  .modal-footer {
    border-top: 1px solid rgba(128, 128, 128, 0.33);
  }
}

.modal-failure-info {
  border-top: 1px solid rgba(128, 128, 128, 0.33);
  display: none;
  p {
    display: block;
    height: 40px;
    text-align: center;
  }
}

.modal-text {
  margin: 0px;
}

.modal-failure-text, .modal-success-text {
  margin: 10px;
}

/*confirm-modal*/

#confirm-modal {
  z-index: 999;
  .modal-title {
    position: relative;
    text-align: center;
    color: white;
    padding-bottom: 5px;
  }
  .modal-header {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 3px 15px 0 15px;
    color: white;
    font-size: 30px;
    margin: 0;
    background-color: rgb(89, 89, 89);
    h2 {
      margin: 0px 0px 0px 2%;
      font-weight: bold;
    }
  }
  .modal-content {
    border-radius: 5px;
    width: 85%;
    max-width:600px;
    margin: 30% auto;
  }
  .modal-header .icon-question-sign {
    padding-right: 5px;
    margin-top: 2px;
  }
}

.confirmTrue {
  margin-right: 21px;
}

.modal-confirm-message {
  padding: 15px 15px 15px 15px;
  // Removed by BB - I'm assuming this keeps coming in for some reason but we need to find that case
  // and fix for that case - modals shouldnt need this 12% left across the board
  // margin-left: 12%;
  font-size: 20px;
  border-radius: 2px;
  color: black;
}

#confirm-modal .modal-footer {
  padding: 10px 0px 10px 0px;
  background-color: white;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

/* ota modal */

#ota-modal .modal-content, #role-modal .modal-content {
  color: white;
  background-color: rgba(89, 89, 89, 1);
}

#upload-ota-file {
  color: white;
}

#ota-channel-select {
  width: 20%;
}

.main-nav a, .utility-nav a {
  text-decoration: none;
}

.nav-image {
  height: 46px !important;
}

.cloud-sync {
  /* ADDED BY B TO FIX SYNC SPINNING IN A WEIRD WAY */
  height: auto !important;
}

#do-part-check {
  display: inline-block;
  -webkit-appearance: checkbox;
}

#cancel-ota {
  color: red !important;
  position: relative !important;
  cursor: pointer;
  left: 40px !important;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  width: 100%;
  -webkit-overflow-scrolling: auto;
  overflow-x: hidden;
}

/*terminal modal stuff*/

body.isCordova {
    #terminal-modal {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        height: 100vh !important;
    }
    #terminal-modal-content {
        max-height: 100vh !important;
        height: 100% !important;
    }
    #terminal-modal-content {
        height: 100vh !important;
    }
}

#terminal-modal {
  position: absolute;
  top: 45vh;
  left: 0;
  height: 40vh;
  width: 100vw;
  padding: 0;
  z-index: 9999;
  &.fullscreeen {
    height: 100vh !important;
    position: absolute;
    top: 0vh !important;
  }
}

#terminal-modal-content {
  position: relative;
  width: 100vw;
  overflow:hidden;
  height: 55vh;
  background-color: black;
  padding: 10px;
  &.fullscreeen {
    height: 100vh !important;
    position: absolute;
    top: 0vh !important;
  }
}

#terminal-modal .modal-dialog {
    margin: 0px;
    padding: 0px;
    max-width: 100vw !important;
    min-width: 100%;
    max-height: 100vh;
    height: 100%;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.9;
    &.fullscreeen {
        max-height: 100vh;
        height: 100%;
    }
}

#the-terminal {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  padding: 0px;
  top: 20px;
  left: 10px;
}

.data-table-in-terminal {
  font-size: 12px;
}

.terminal-data-input {
  height: 30px;
  font-size: 12px;
  margin-top: 5px;
}

.terminal-action-table, .data-table-in-terminal{
  font-size: 12px;
  border: 1px solid rgb(65, 116, 208);
  width:99%;
  caption {
    color: white;
    font-weight: bolder;
    font-size: 15px;
    margin-bottom: 5px;
  }
  tbody {

    tr {
      border: 1px solid rgb(65, 116, 208);
      text-align: center !important;
      min-width: 40px;
      padding: 3px;
      th, td{
        text-align: center !important;
        border: 1px solid rgb(65, 116, 208);
        padding: 5px;
        font-weight: bold;
      }
    }

  }
}

// td {
//   border: 1px solid rgb(65, 116, 208);
//   text-align: center !important;
//   min-width: 40px;
//   padding: 3px;
// }

.terminal-action-table

.cancel-ota-terminal {
  margin-left: 15px;
  font-size: 20px;
}

#terminal-text-body-container {
  width: 100%;
  vertical-align: bottom;
  max-height: 82%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &.fullscreeen {
    max-height: 94%;
  }
}

#terminal-text-body {
  color: white;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
  margin: 10px;
}

#terminal-input-container {
    padding-left: 10px;
    padding-top: 10px;
}

.loc-list-settings-dropdown {
  background-color: rgba(62, 62, 62, 1);
  color: white;
  width: 150px;
  font-size: 15px;
  display: none;
  position: absolute;
  right: 48px;
  top: 43px;
  z-index: 100;
  border-radius: 2px;
  vertical-align: middle;
  box-shadow: -3px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

#fullscreen-terminal {
    background: unset;
    z-index: 10;
    font-size: 34px;
    position: absolute;
    right: 50px;
    padding: 0;
    margin: 0;
}

#close-terminal {
    background: unset;
    z-index: 10;
    font-size: 34px;
    position: absolute;
    right: 20px;
    opacity: 1;
    padding: 0;
    margin: 0;
}

#terminal-user-info {
  color: white;
  font-size: 15px;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
}

#command-text, #login-put {
  width: 65%;
  color: white;
  background-color: black;
  display: inline-block;
  font-family: "Lucida Sans Typewriter", "Lucida Console", Monaco, "Bitstream Vera Sans Mono", monospace;
}

.terminal-ota {
  cursor: pointer;
  font-size: 25px;
}

#upload-ota-file-terminal {
  color: white;
}

/* New Device Modal Stuff */

#new-device-modal-content {
  position: relative;
  width: 200%;
  height: 800px;
  background-color: black;
  right: 45%;
  top: 20%;
  border: 1px solid rgb(65, 116, 208);
  overflow: scroll;
  /*max-height: ;*/
}

#cursor {
  font-size: 20px;
  color: white;
  -webkit-animation: 1s blink step-end infinite;
  -moz-animation: 1s blink step-end infinite;
  -ms-animation: 1s blink step-end infinite;
  -o-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from, to {
    color: transparent;
  }

  50% {
    color: white;
  }
}


@-moz-keyframes blink {
  from, to {
    color: transparent;
  }

  50% {
    color: white;
  }
}


@-webkit-keyframes blink {
  0%, 100% {
    color: transparent;
  }

  50% {
    color: white;
  }
}


@-ms-keyframes blink {
  from, to {
    color: transparent;
  }

  50% {
    color: white;
  }
}


@-o-keyframes blink {
  from, to {
    color: transparent;
  }

  50% {
    color: white;
  }
}


#sensors, #dimmers, #others, #switchs {
  margin-bottom: 20px;
}

.edit-name-header {
  text-transform: uppercase;
  font-weight: bold;
}

#edit-name-modal .modal-header {
  background: #3179b4;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.edit-name-input {
  padding: 16px;
}

#edit-name-modal input {
  margin: 0;
  position: relative;
  margin-right: 0px !important;
  background: rgba(128, 128, 128, 0.11);
  border: 1px solid rgba(0, 0, 0, 0.45);
  border-radius: 5px;
  /* content: 'EDIT'; */
}

.add-loc-indiv, .add-loc-to-sub {
  position: absolute;
  color: white;
  text-align: center;
  background: #a09f9f;
  width: 40px;
  height: 100%;
  top: 0;
  z-index: 999;
  /* left: 155px; */
  float: right;
  left: 73px;
  border-right: 1px solid #686868;
  border-left: 1px solid #686868;
}

.add-loc-indiv:hover, .add-loc-to-sub:hover {
  color: #4f4f4f;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
//   &.fade .modal-dialog {
//     -webkit-transition: -webkit-transform .3s ease-out;
//     -o-transition: -o-transform .3s ease-out;
//     transition: transform .3s ease-out;
//     -webkit-transform: translate(0, -25%);
//     -ms-transform: translate(0, -25%);
//     -o-transform: translate(0, -25%);
//     transform: translate(0, -25%);
//   }
//   &.in .modal-dialog {
//     -webkit-transform: translate(0, 0);
//     -ms-transform: translate(0, 0);
//     -o-transform: translate(0, 0);
//     transform: translate(0, 0);
//   }
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(54, 52, 54, 0.53);
  &.fade {
    filter: alpha(opacity = 0);
    opacity: 0;
  }
  &.in {
    filter: alpha(opacity = 50);
    opacity: .5;
  }
}

.modal-header {
  min-height: 16.42857143px;
  padding: 15px;
  display: block;
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  .close {
    margin-top: -2px;
    background: none;
    color: white;
    margin: 0;
    top: -34px;
    position: relative;
    font-size: 25px;
    z-index: 999;
  }
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  /* padding: 15px; */
  overflow: auto;
  display: block;
  width: 100%;
  height: 92%;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  position: relative;
  height: 10%;
  display: block;
  border-top: 1px solid #e5e5e5;
  .btn + .btn {
    margin-bottom: 0;
    margin-left: 5px;
  }
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

.modal-footer {
  &:before {
    display: table;
    content: " ";
  }
  &:after {
    display: table;
    content: " ";
    clear: both;
  }
}

.glyphicon-modal-window:before {
  content: "\e237";
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}
.mobile-header{
  display: none;
  width: 240px;
  margin: auto;
  margin-top: 47px;
  margin-bottom: 20px;
  p{
    margin-top: 10px;
    color: white;
    text-align: center;
    font-size: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.root-header{
  .mobile-header{
    display: none;
    text-align: left;
    margin: inherit;
    padding: 13px 10px 10px 14px;
    color:white;
    .logo-container{
      margin-bottom: 8px;
    }
    #site-header{
      font-size: 27px;
      margin-right: 10px;
      text-align: left;
    }
    img{
      height:auto;
      width:95px;
    }
  }
}
.desktop-header{
  border-bottom: solid;
  border-color: #6d6d6d;
  border-width: 1px;
  margin: auto;
  margin-bottom: 180px;
  padding-top: 35px;
  .logo-container{
    padding-left: 20px;
    img{
      max-width: 200px;
    }
  }
  p{
    color: white;
    font-size: 26px;
    margin-top: 30px;
    padding-bottom: 20px;
    padding-right: 10px;
    text-align: right;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
#site-list-container,
#users-container {
  $max-width: 380px;
  $border-radius: 5px;
  min-height: 620px;
  background-color: #2d2d2d;
  padding-left: 75px;
  padding-right: 75px;
  // position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 1200;
  margin: 0;
  #user-site-list-container{
    max-width: 600px;
    margin-top: 50%;
    margin:auto;
    #site-list-user-create-new{
      position: relative;
      float: left;
      padding: 15px;
      font-size: 19px;
      color: white;
      text-decoration: none;
      z-index:999;
      cursor:pointer;
    }
    #site-list-user-logout{
      position: relative;
      float: right;
      padding: 15px;
      font-size: 19px;
      color: white;
      text-decoration: none;
      z-index:999;
      cursor:pointer;
    }
  }
  .desktop-header{
      margin-bottom:0px;
  }
  #login-status-error{
    max-width: $max-width;
    margin: auto;
    .error, .success{
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0px;
      border-radius: $border-radius;
    }
  }
  #login-status-success{
    max-width: $max-width;
    margin: auto;
    .error, .success{
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0px;
      border-radius: $border-radius;
    }
  }
  #app-version {
    position: absolute;
    bottom: 10px;
    color: grey;
    right: 75px;
  }
  #login-user-sites{
    height: 600px;
    padding-top: 5px;
    padding-left: 3px;
    ul{
      height: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

#users-container {
    .desktop-header{
        margin-bottom: 20px;
    }
}

.list-bottom-border{
  border-bottom: 1px solid #6d6d6d;
}

/* For Firefox */
input[type='number'] {
    -moz-appearance:textfield;
}
/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#login{
  $max-width: 380px;
  $border-radius: 5px;
  min-height: 620px;
  background-color: #2d2d2d;
  padding-left: 75px;
  padding-right: 75px;
  .line {
    max-width: 380px;
    height: 10px;
    padding: 0;
    margin: 0 auto;
  }
  #site-token {
      margin-bottom: 0 !important;
  }
  #login-status-error{
    max-width: $max-width;
    margin: auto;
    .error, .success{
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0px;
      border-radius: $border-radius;
    }
  }
  #login-status-success{
    max-width: $max-width;
    margin: auto;
    .error, .success{
      padding-top: 15px;
      padding-bottom: 15px;
      margin-bottom: 0px;
      border-radius: $border-radius;
    }
  }
  #login-form{
    top: 20px;
    input{
      background-color: #d8d8d8;
      max-width: $max-width;
      margin: auto;
      margin-bottom: 10px;
      padding: 15px;
      font-size: 22px;
      text-transform: none;
      border-radius: $border-radius;
    }
    .button-login{
      width: $max-width;
      margin: auto;
      margin-bottom: 10px;
      font-size: 22px;
      text-transform: none;
      border-radius: $border-radius;
      background-color: #3f51b5;
    }
    .button-login:hover{
      background-color: #303f9f;
      color: white;
    }
    .button-login:active{
      background-color: #303f9f;
      color: white;
    }
    .button-login:focus{
      background-color: #303f9f;
      color: white;
    }
  }
  .mfa-button-container{
    max-width: 380px;
    margin: auto;
    #show-input-MFA{
      height: 45px;
      background-color: transparent;
      color: white;
      float: right;
    }
  }
  .submit-button-container{
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    #site-submit{
      width: 100%;
      max-width: 380px;
      margin: auto;
      color: white;
      float: none;
      margin-top: 10px;
    }
  }
  .secondary-logins-container{
    width: 200px;
    margin: auto;
    text-align: center;
    margin-top: 23px;
    button{
      margin: auto;
      background-color: transparent;
    }
    button, a{
      margin-bottom: 4px;
    }

  }
  #app-version {
    position: absolute;
    bottom: 10px;
    color: grey;
    right: 75px;
  }
}
#new-site-container{
  display: none;
  min-height: 620px;
  background-color: #2d2d2d;
  padding-left: 75px;
  padding-right: 75px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  z-index: 10000;
  margin: 0;
    input{
      padding: 15px;
      font-size: 22px;
      text-transform: none;
      border-radius: 5px;
      max-width: 380px;
      width: 100%;
      margin: auto;
      float: none;
      margin-bottom: 10px;
    }
    #site-name{
      background-color: #d8d8d8;
    }
    .button{
      max-width: 380px;
      margin: auto;
      float: none;
      margin-top: 10px
    }
    #submit-new-site{
      background-color: dimgrey;
      color: white;
    }
    #submit-new-site.enabled{
      background-color: #0074d4;
    }
    .cancel{
      margin: auto;
      text-align: center;
      width: 50px;
      display: block;
      color: white;
      margin-top: 12px;
      font-size: 16px;
      cursor: pointer;
    }
    .text-explanation{
      margin: auto;
      text-align: center;
      width: auto;
      display: block;
      color: white;
      margin-bottom: 35px;
      font-size: 28px;
      line-height: 36px;
    }
}
#location-list{
  position: absolute;
  //50 px is the height of the location list header
  height: calc(100% - 50px);
  width: 100%;
  button{
    height: auto;
    width: auto;
    padding: 10px;
  }
  .list-item-active{
    background-color: #616161;
  }
  .location-list-item-2{
    padding-bottom: 8px;
    padding-top: 8px;
    height: 40px;
    flex-direction: row;
    align-content: center;
  }
  .location-list-item{
    .action-icon-container{
      width: 14px;
      height: 14px;
    }
    .location-list-chevrons{
      // margin-top: -10px;
      // margin-bottom: -10px;
      color: white;
      font-size: 14px;
      display: block;
      margin: auto;
    }
    .text-field{
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 4px;
      input{
        padding-bottom: 0px;
        padding-top: 2px;
        color: #BDBDBD;
      }
    }
    .checkbox{
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 4px;
    }
    .location-list-icons {
      margin-right: 0px;
      min-width: 20px;
      justify-content: flex-start;
      display: flex;
      align-content: center;
      svg{
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    .list-action-items{
      white-space:nowrap;
      display: none;
      button{
        height: auto;
        padding: 0px;
      }
      .edit,.add,.delete{
        padding: 6px;
        margin-left: 6px;
        margin-right: 6px;
      }
      .add:hover{
        background-color: #0074d4;
      }
      .delete:hover{
        background-color: #C23030;
      }
      svg{
        color: white;
        font-size: 14px;
        display: block;
        margin: auto;
      }
    }
    .list-action-items.name-is-edit{
      display:block;
    }

    .location-list-item-2:hover > .list-action-items{
       display: block;
    }
    .location-name-container{
      flex: 1 1 80%;
      padding: 0px 0px 0px 2px;
      justify-content: flex-start;
      align-items: center;
      display: flex;
    }
  }
  .location-readout-container{
    flex: 1 1 20%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    span{
      margin-left: 4px;
    }
    .light-level-li{
        width: 45px;
        text-align: right;
    }
    .load-shed-li{
      margin-right: 3px;
    }
  }
}
.ReactVirtualized__List:focus{
  outline: none;
}

#scene-list{
//   position: relative;
  //50 px is the height of the scene list header
//   height: 400px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 0px !important;

  .scene-list-item{
    .text-field input{
      padding-bottom: 0px;
      padding-top: 2px;
      color: #BDBDBD;
    }
    padding-right: 0;
    padding-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    .list-item-text{
      white-space: nowrap;
      padding-left: 5px;
      padding: 0;
      span{
        //primary text
        color: white;
        font-size: 16px;
      }
      p{
        color: white;
        font-size: 12px;
      }
      .list-item-secundary {
        font-size: 12px !important;
        svg {
            margin-right: 4px;
        }
        span {
            font-size: 12px !important;
            margin-right: 4px;
        }
        span.block {
            display: block;
        }
      }
    }
    .checkbox{
      font-size: 14px;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-right: 4px;
    }
    .list-action-items{
      margin-top: -10px;
      margin-bottom: -10px;
      display: none;
      display: flex;
      justify-content: center;
      float: right;
      button{
        height: auto;
        padding: 0px;
      }
      .edit,.add,.delete{
        padding: 6px;
        //margin-left: 6px;
        //margin-right: 6px;
      }
      .add:hover{
        background-color: #0074d4;
      }
      .delete:hover{
        background-color: #C23030;
      }
      svg{
        color: white;
        font-size: 14px;
      }
    }
  }
  .scene-list-item:hover >.list-action-items{
    //display: block;
  }
  .scene-readout-container{
    color: white;
    margin-right: 40px;
  }
}
.edit-list-button-container{
  position: absolute;
  bottom: 5px;
  right: 5px;
  .add-root-location{
    background-color: #0074d4;
    margin-left: 15px;
    margin-right: 15px;
  }
  .add-root-location:hover{
    background-color: #338fdc;
  }
  .done{
    background-color: #248f2d;
  }
  .done:hover{
    background-color: #2a9833;
  }
  .edit-mode{
    color: #ffffff !important;
    span{
      margin-left: 4.5px;
      margin-bottom: 2.5px;
    }
  }
  .edit-mode a{
    color: #ffffff !important;
  }
  .edit-mode:hover{
    background-color: lightgrey;
    color: #ffffff !important;
    cursor: pointer !important;
  }
  .button-qr {
    background-color: grey;
    margin-left: 10px;
  }
  .button-qr:hover {
    background-color: lightgrey;
  }
  .delete-location-button{
    background-color: #C23030;
    svg{
      color: white;
    }
  }
  .delete-location-button:hover{
    background-color:  #f6685e;
  }
  svg{
    font-size: 18px;
  }
}
#device-list .signal-strength-icon{
  font-size: 16px;
  margin-right: 30px;
  margin-top: -42px;
}
#page-content{
  overflow-x: hidden;
  padding: 12px;
  .content-container{
    height: 100%;
    background-color: #4f4f4fd6;
    padding: 25px;
    border-radius: 12px;
    &.h-100 {
        height: 100% !important;
    }
    &.maxh-box {
        height: 550px !important;
    }
    &.relative {
        position: relative;
    }
   .header-container{
      display: block;
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      h2{
        float: left;
        font-size: 22px;
      }
    }
  }
  .onOff{
    font-size: 36px;
    .on{
      color: yellow;
    }
    .off{
      color: white;
    }
  }
}


$padding: 15px;
#user-settings{
    background-color: #4f4f4fd6;
    color: white;
    height: 100%;
    text-align: left;
    font-size:  20px;
    svg, span{
        color: white;
    }
    .user-info-container{
      padding: $padding;
      padding-bottom: 20px;
      span{
        padding-bottom: 5px;
        display: block;
      }
      .avatar{
        margin-bottom: 15px;
        height: 50px;
        width: 50px;
      }
   }
  .settings-action-divider{
    margin-left: -$padding;
    margin-right: -$padding;
  }
 }
 .nav-icons-container{
   position: fixed;
   height: 100%;
   width: $nav-icons-width;
   background-color: #4f4f4fd6;
 }
 div[role='navigation']{
  left: $nav-icons-width !important;
  transition-property: bottom;
  transition-duration: 200ms;
 }
 #nav-icons{
   svg{
     font-size: 26px;
     color: #BDBDBD;
   }
   span{
    color: #BDBDBD;
   }
   a:hover svg{
     color: white;
   }
   a:hover span{
    color: white;
  }
   a.active{
     svg{
       color: white;
     }
     span{
       color: white;
     }
   }
   #user-icon{
     position: fixed !important;
     bottom: 15px;
   }
 }
 .fab-container{
  position: absolute;
  bottom: 20px;
  right: 20px;
  .edit-mode{
    background-color: grey;
  }
  .edit-mode:hover{
    background-color: lightgrey;
  }
 }
 .edit-mode{
    color: #ffffff !important;
  }
  .edit-mode:hover{
    cursor: pointer !important;
  }
 #page-content .fab-container{
   position: fixed;
 }
.terminalContainer{
  //B: this # needs to be tied to JS somehow - I couldn't figure out how to pass to component in react
  height: 250px !important;
};
.terminal-drawer{
  .exit-button-container{
    color: black;
    position: absolute;
    right: 5px;
    button{
      color: white;
    }
  }
}
#devices-header-div{
  transition-property: height;
  transition-duration: 2s;
}
.rangeslider{
  box-shadow: none;
}
.rangslider:focus{
  outline: none;
}
.rangeslider__handle:after {
  background-color: white !important;
  box-shadow: none !important;
}
.rangeslider__handle:focus{
  outline: none !important;
}
.rangeslider-horizontal{
  height: 3px;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #3e95cd;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__fill {
  // background: rgb(2,0,36);
  // background: linear-gradient(357deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 1%, rgba(62,149,205,1) 100%);
  background-color: #3e95cd;
  box-shadow: none;
}
.rangeslider__fill {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.rangeslider-vertical{
  background-color: white;
  max-width: 30px;
  border-radius: 5px;
  .rangeslider__handle {
    border-radius: 5px;
    background: grey;
    width: 50px;
    height: 18px;
    left: -10px;
    border: none;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  }
}



#sync-list{
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 110px;
  overflow: auto;
  //50 px is the height of the location list header
  height: calc(100% - 50px);
  span{
    color: white;
  }
  .sync-status-container{
    float: right;
    padding-right: 60px;
    .sync{
      animation: spin-animation $spin-animation-speed infinite linear;
    }
  }
  @keyframes spin-animation {
    0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(359deg);
      }
  }
}
.location-features-text{
  display: inline-block;
}
.secondary-list-item-container{
  float: right;
  margin-right: 50px;
  text-align: right !important;
}
button > span {
  padding-top: 1px;
}

.direct-controls-container{
  .on-off-container{
    button{
      background-color: grey;
      color: white;
      svg{
        margin-left: 10px;
        font-size: 16px;
      }
      width: 90px;
    }
    // svg{
    //   display: block;
    //   color: white;
    //   font-size: 22px;
    //   margin: auto;
    // }
    .top{
      margin-top: 15px;
      margin-bottom: 75px;
    }
  }
  .light-level-text{
    margin-top: 62px;
    float: left;
    h1{
      float: left;
    }
  }
}
.empty-state{
  /* padding-top: 55px; */
  .fa-lightbulb-on, .fa-building, .fa-lightbulb{
    font-size:36px;
    margin-bottom:15px;
  }
  svg{
    color: white;
  }

}
.snack-content{
  padding: 20px !important;
  span{
    display: inline-block;
  }
  .text-container{
    padding-left: 10px;
  }
  .close-icon{
    position: absolute;
    top: 12px;
    right: 18px;
  }
  .snack-icon{
    font-size: 20px;
  }
  h4{
    font-weight: bold;
  }
}
.error-container-mqtt{
  background-color: #b2ebf2 !important;
}
.warning-container-mqtt{
  background-color: #ffff8d !important;
}
.error-container{
  background-color: #d9534f !important;
}
.warning-container{
  background-color: #ff9800 !important;
}
.info-container{
  background-color: #2196f3 !important;
  color: black;
}
.success-container{
  background-color: #248f2d !important;
}
.location-empty-state{
  .fa-lightbulb{
    font-size: 30px;
    margin-bottom: 20px;
  }
  h2{
    span{
      display:inline-block;
      vertical-align: middle;
        .fa-arrow-left{
          font-size: 24px;
        }
    }
    span:first-child{
        right:20px;
        position:relative;
    }
  }
  margin-bottom: 10px;
}
.location-container{
  margin-bottom: 30px;
  border-radius: 12px;
  background-color: #242424;
  display: block;
  padding: 25px;
  &.mh-80 {
    min-height: 80px !important;
  }
  &.cursor-p {
      cursor: pointer;
  }
  .location-container-grid{
    justify-content: center;
  }
  .location-container-component{
    // margin-top: 8px;
    min-width: 300px;
    // max-width: 590px;
    // max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
    // padding-top: 0px !important;
    // padding-bottom: 0px !important;
  }
  .location-container{
    margin-bottom:0px;
  }
  .status-icon-container{
    width: 25px;
    height: 25px;
    display: inline-block;
    text-align: center;
  }
}
.action-overlay-container{
  background-color: rgba(0, 0, 0, .6);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 50000;
  top: 0px;
  .image-container{
    position: absolute;
    left: 50%; /* relative to nearest positioned ancestor or body element */
    top: 50%; /*  relative to nearest positioned ancestor or body element */
    transform: translate(-50%, -50%); /* relative to element's height & width */
  }
}
.location-header-container{
  float: left;
}
.settings-container{
  /* float: right; */
  /* text-align: right; */
  font-size: 30px;
  /* margin-right: 33px; */
  color: #fff;
  /* margin-top: 25px; */
  cursor: pointer;
  right: -25px;
  /* left: 0px; */
  position: relative;
  right: 25px;
  float: right;
}
.settings-container:hover{
  color: grey;
}
.root-header{
  .desktop-header{
    margin-bottom: 5px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    .logo-container img {
      max-width: 150px;
    }
    p{
      margin-top: 10px;
      height: 30px;
      margin-bottom: 15px;
    }
  }
}
.location-menu-settings-drawer{
  //position: initial !important;
}
.renameSiteDialog{
  min-width: 375px;
  #alert-dialog-title{
    text-align: center;
  }
  .techModeSwitch{
    margin: auto;
  }
}
.not-found-container{
  width: 450px;
  margin: auto;
  margin-top: 150px;
  .icon-container{
    display: block;
    svg{
      font-size: 80px;
    }
  }
  .copy-container{
    display: block;
    .button-container{
      margin-top: 15px;
    }
  }
}
#react-select-1-input,
#react-select-2-input,
#react-select-3-input,
#react-select-4-input,
#react-select-5-input,
#react-select-6-input,
#react-select-7-input,
#react-select-8-input,
#react-select-9-input,
#react-select-10-input {
  padding-top: 20px !important;
}
.role-select-modal{
  color: white !important;
}
.text-error {
    color: orange;
}
.eventStepper{
  background-color: initial !important;
  p{
    color: white;
  }
  overflow-x: scroll;
}

#table-events-main {
    button[class^="Pagination-button-"],
    button[class*=" Pagination-button-"] {
        display: none;
    }

    span[class^="Pagination-rowsLabel-"],
    span[class*=" Pagination-rowsLabel-"] {
        display: none;
    }
}

#panel-pagination {
    margin: 10px 20px;
    .pagination-button-actions {
        display: flex;
        .button-action-back {
            display: flex;
            padding-right: 50px;
            span.date-info {
                flex: 0 0 auto;
                padding: 12px 0px 12px 0;
            }
        }
        .button-action-next {
            display: flex;
            padding-right: 50px;
            span.date-info {
                flex: 0 0 auto;
                padding: 12px 0px 12px 0;
            }
        }
    }
}
.input-container{
  margin-top: 20px;
}
.fade-settings-container{
  margin-top: 20px;
  margin-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
}
input[type=range]{
  appearance: none;
  width: 100%; /* Full-width */
  height: 3px; /* Specified height */
  padding: 0px 0px;
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 30px; /* Set a specific slider handle width */
  height: 30px; /* Slider handle height */
  background: white; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 50%;
  box-shadow: 0 6px 18px 0px rgba(0,0,0,.14), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.20);
}


#direct-control-slider {
  -webkit-appearance: none;
  max-width: 150px;
  min-width: 150px;
  margin-left: -30px;
  margin-top: 75px;
  transform: rotate(270deg);
  padding: 12px 0px;
  background-color: #3F95CD;
  position: relative;
  border-radius: 3px;
  background: linear-gradient(to right, #3F95CD 0%, #3F95CD 50%, #fff 50%, #fff 100%);
}

#direct-control-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  left: -20px;
  animate: 0.2s;
  border-radius: 1.3px;

}
#direct-control-slider::-webkit-slider-thumb {
  height: 70px;
  width: 28px;
  padding: 10px 10px;
  position: relative;
  top: -15px;
  border-radius: 5px;
  background: grey;
  color: red;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
  border: 0px;
  border: none;
  box-shadow: 0 6px 18px 0px rgba(0,0,0,.14), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.20);
}
//MOZ STUFF

#direct-control-slider::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  left: -20px;
  animate: 0.2s;
  border-radius: 1.3px;
}
#direct-control-slider::-moz-range-thumb {
  height: 65px;
  width: 15px;
  padding: 10px 10px;
  position: relative;
  top: -15px;
  border-radius: 5px;
  background: grey;
  color: red;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
  border: 0px;
  border: none;
  box-shadow: 0 6px 18px 0px rgba(0,0,0,.14), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.20);
}
#direct-control-slider::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
#direct-control-slider::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
#direct-control-slider::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
#direct-control-slider::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
#direct-control-slider:focus::-ms-fill-lower {
  background: #3071a9;
}
#direct-control-slider:focus::-ms-fill-upper {
  background: #367ebd;
}
#scene-search-bar-container {
    padding-top: 0.6em;
}
.border-gray {
    border-bottom: 1px solid #626262 !important;
    background-color: transparent !important;
}

.button-calc {
    display: inline-block;
    padding: 20px 10px;
    color: black;
    font-size: 24px;
    cursor: pointer;
}

.color-white {
    color: white !important;
}

.chip-beta {
    color: white;
    background: green;
    margin-left: 2px;
    font-size: 10px;
    border-radius: 7px;
    padding: 2px 4px;
    font-weight: bold;
    vertical-align: sub;
}

.flex {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .row {
        display: flex;
        flex-direction: row;
        margin: 0 !important;
    }
    .content-start {
        justify-content: flex-start;
        margin-left: 10px !important;
    }
    .content-end {
        justify-content: flex-end;
        margin-right: 10px !important;
    }
}

.link-white {
    cursor: pointer;
    &:hover, &:focus {
        color: #9e9e9e !important;
    }
}
.link-white-mqtt {
  cursor: pointer;
  color: #006064 !important;
  &:hover, &:focus {
      color: #9e9e9e !important;
  }
}
.refresh-icon {
    float: right;
    color: white !important;
    padding: 0 !important;
}

.dashboard-config-icon {
  color: white !important;
  padding: 0 5px !important;
}

.snackbar-content {
    max-width: 100% !important;
    min-width: 100% !important;
    margin-bottom: 10px  !important;
}

.loader-container {
    height: 54px;
}

.test-mode-switch {
    flex-direction: row-reverse !important;
}

.circular-progress {
    width: 20px !important;
    height: 20px !important;
    display: block !important;
    margin: 0 auto !important;
}
.connection-status-container-master{
  display: flex;
  justify-content: center;
  align-items: center;
}
.connection-status-container {
    position: fixed;
    width: 100%;
    margin-top: 97.2vh;
    z-index: 6;
}
.connection-status-content {
  width: 70%;
  border-radius: 4px;
  div {
    color: #9e9e9e;
    justify-content: center;
    display: flex;
    font-weight: bold;
    font-size: 12px;
  }
}

@media (max-width: 420px) {
  .connection-status-container {
    display: none;
  }
}
.MuiSwitch-colorPrimary.Mui-checked{
  color: white !important;
}

.search-location-modal{
  max-height: 85%;
  margin-top: 30px;
}

.search-location-modal-active{
  text-decoration: none !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
}

#section-csv-download-report > * {
  margin: 20px;
  max-width: 85%;
}

.download-button {
  color: white;
  cursor: pointer;
  border: 1px solid transparent;
  display: inline-block;
  padding: 10px 39px;
  font-size: 0.9375rem;
  text-align: center;
  font-weight: normal;
  white-space: nowrap;
  line-height: 1.42857143;
  user-select: none;
  touch-action: manipulation;
  margin-bottom: 0;
  border-radius: 4px;
  vertical-align: middle;
  text-decoration: none;
  background-color: #3f51b5;
  background-image: none;
}

.download-button:hover {
  background-color: #303f9f;
  text-decoration: none;
  color: white;
}

.app-version{
  display: flex;
  #intercom_amatis{
    margin-left: 10px;
    #icon-help{
      margin-left: 5px;
    }
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}
.width-75 {
  width: 55%;
}
.modal-create-user{
  background-color: white;
  border-radius: 5px;
  padding: 4px;
  overflow: auto;
  max-height: 75%;
  header{
    background-color: white;
    position: inherit;
  }
  .input-text-form{
    width: 100%;
    margin-top: 15px;
    font-size: 10px;
  }
  .input-text-form label{
    color: black;
    font-size: 14px;
  }
  #title{
    font-weight: 800;
    color: black;
    font-size: 30px;
  }
  .content-tabs{
    padding: 20px;
  }
}
.modal-create-user-inputs{
  padding: 30px;
  overflow: auto;
}

.modal-input-select{
  margin-top: 20px !important;
}

.center-circle-animation{
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 10%;
}

.icon-container{
  width: 30px;
}
.icon-container-location{
  width: 30px;
  margin-top: 3px;
}

.RoomTypeListItem{
  padding-right: 10px;
}

.roomTypeSelector{
  display: flex !important;
}

.location-feature-text-container{
  min-width: 200px !important;
}
.container-buttons-settings{
  float: right;
  margin-top: -30px;
}
.done-actions-button{
  margin: 5px !important;
  color: white !important;
}
.delete-actions-button{
  margin: 5px !important;
  color: white !important;
}
.add-actions-button{
  margin: 5px !important;
}
.edit-list-button-container-scenes{
  float: right;
  margin-top: -31px;
}
.edit-action-container-scenes{
  margin-top: -12px;
  width: 100%;
}
.edit-list-button-container-location{
  margin-top: -43px;
  margin-right: 10px;
  float: right;
}
.edit-list-button-container-location{
  .done-actions-button{
    padding: 0px 10px !important;
  }
  .add-actions-button{
    padding: 0px 10px !important;
  }
  .delete-actions-button{
    padding: 0px 10px !important;
  }
}
.location-puzzle-icon{
  min-height: auto !important;
  width: 30px !important;
  height: 30px !important;
  margin-right: 2px !important;
}
.settings-devices-container{
  margin-top: -12px;
}
.edit-list-button-container-location-button{
  margin-top: -38px;
  margin-right: 14px;
  float: right;
}

#location-validator-table {
    max-height: 480px;
    background-color: #484848;
    text-align: left;
    ul {
        line-height: 2;
        li {
            padding: 10px 5px;
            p {
                font-size: 15px;
                line-height: 1.7;
            }
            .issue-error-title {
                font-size: 18px;
                span {
                    font-size: 13px;
                }
            }
        }
    }
}

.devices-actions-modal {
  position: fixed;
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 9999;
  width: 80%;
  height: 60px;
  z-index: 999;

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    &-selected {
      &-text{
        color: black;
      }
    }
    
   
    div:first-child {
      flex-grow: 2;
    }
    div:nth-child(2) {
      flex-grow: 15;
      display: flex;
    }
    div:last-child {
      flex-grow: 1;
    }

    &-close-button {
      height: 100%;
      background-color: #0664b2;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      button {
        width: 100%;
        height: 100%;
        color: white;
      }
    }
  }

  @media screen and (max-width: 520px) {
    height: 100px;
    width: 60%;
    &-container {
      display: flex;
      flex-direction: column;
      > * {
        width: 100%;
      }
      div:first-child {
        flex-grow: 1;
      }
      div:nth-child(2) {
        flex-grow: 1;
      }
      div:last-child {
        flex-grow: 1;
      }

      &-close-button {
        border-top: 3px;
      }
    }
  }
}

.modal-buttos-papel{
  border-radius: 20px !important;
  z-index: 9999;
  margin: 27px !important;
  margin-left: 80px !important;
}
.white-space-configtable{
  width: 90vw;
  height: 15vh;
  margin: auto;
}
.text-black{
  h2{
    color: #000000;
  }
}
.menu-button-events{
  margin: 5px;
  height: 16px;
  background-color: black;
}

.input-select-custom {
  .MuiInputBase-input{
    height: auto !important;
  }
  .MuiInputBase-input div:first-child{
    flex-wrap: wrap;
  }
}
.csv-button {

  text-decoration: none;
  color: black;
  &:focus, &:hover, &:active {
      text-decoration: none;
      color: black;
  }
}
.color-label{
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px !important;
}

.amatis-config-buttons-container {
  flex-wrap: "wrap";
}

.amatis-button-action {
  margin: 0;
  padding: 2px 7px !important;
  @media (max-width: 520px) {
    &-text {
      width: 50px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.container-row-device-groups {
  position: relative;
  overflow: visible !important;
}

.td-device-groups-container {
  display: flex !important;
  justify-content: normal;
  align-content: space-between;
  flex-direction: row;
  align-items: center;
  overflow-x: auto !important;
}
.td-device-groups-container::-webkit-scrollbar {
  height: 8px !important;
}
.td-device-groups-container::-webkit-scrollbar-thumb {
  background: #088fff !important;
}

.item-device-groups {
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  margin: 2px;
  color: #FFFFFF;
}

.item-device-groups-grouping {
  display: inline-flex;
  background-color: #84C1FF;
  border-radius: 10px;
  margin: 2px;
  padding: 5px 10px 5px 10px;

}
.chip-root-class {
  margin: 2px;
}
#schedule-header-div {
  overflow-y: scroll;
  overflow-x: hidden;
}

.iconFeatureAnimate {
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

.buttonColorTransition {
  -webkit-animation: colorBackground 2.5s linear infinite;
  -moz-animation: colorBackground 2.5s linear infinite;
  animation: colorBackground 2.5s linear infinite;
}

@-moz-keyframes colorBackground {
  from {background-color: #4f4f4fd6;}
  to {background-color: lightgrey;}
}
@-webkit-keyframes colorBackground {
  from {background-color: #4f4f4fd6;}
  to {background-color: lightgrey;}
}
@keyframes colorBackground {
  from {background-color: #4f4f4fd6;}
  to {background-color: lightgrey;}
}

@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
      -webkit-transform: rotate(360deg);
      transform:rotate(360deg);
  }
}

.dx-multiview-item-content {
  overflow-y: scroll;
}

body.scanner-active {
  --background: transparent;
  --ion-background-color: transparent;
}