@import "main";

p, span, div, .all-features-label {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ghost-action-info {
  color: #3db49b;
}
.ghost-action-item{
  margin-left:15px;
}

.ghost-action-pretty {
  color: #329b32;
  margin-left:24px;
}
.ghost-action-group {
  color: #7a7af2;
  font-size:14px;
  line-height:2;
}

#terminal-text-body {
  span {
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select: text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }
  div {
    -webkit-user-select: text !important;
    -khtml-user-select: text !important;
    -moz-user-select:text !important;
    -ms-user-select: text !important;
    user-select: text !important;
  }
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select:text !important;
  user-select: text !important;
}
.terminal-custom-ota{
  input{
    color:white;
  }
}
.skip-part-check-terminal{
  width:40px;
  display:inline-block;
  position: relative;
  top:-1px;
}

.show-fade-icon{
  margin-left:8px;
  font-size:12px;
}

.actionable-list{
  width:100%;
  span{
    font-weight:bold;
  }
}

.actionable-header{
  background-color:#232323;
  .subheader{
    color:white;
    font-weight:bold;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.actionable-device-item{
  border-radius:3px;
  margin-left:5px;
}

.scene-control:active {
  background: grey !important;
}


input[type="search"]{
  font-size:13px;
}

.modal-dialog {
  z-index: 5;
  width: unset;
  min-width: 40%;
  max-width: 100%;
  height: 99%;
  /* height: 100%; */
  /* max-height: 77%; */
  margin: 7px auto;
  overflow: auto;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 24px;
}

#devices-modal{
  max-width: 80%;
}
.success, .error {
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.46);
  margin-bottom: 17px;
  padding: 32px 10px 30px 5px;
}


#start-ota-button{
  margin: 20px 5px 0px 0px;
  padding: 5px;
  width: 100px;
  text-align: center;
  border-radius: 5px;
  background: #025ca0;
  cursor: pointer;
}

#page-wrapper {
  overflow: hidden !important;
}

#features-list {
  position: relative;
  width: 100%;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  /* padding: 10px; */
  background: #4f4e4f;
  > li {
    border-bottom: 1px solid rgba(31, 32, 31, 0.5);
    position: relative;
    background: #4f4e4f;
    display: block;
    > .list-item-content {
      right: 3%;
      height: 100%;
      width: 91%;
    }
  }
  li {
    margin-left: 4px;
    margin-right: 8px;
  }
}

.loc-settings-dropdown-button {
  margin: 0;
  height: 53px !important;
  text-align: center;
  vertical-align: middle;
  width: 5% !important;
  right:unset !important;
  border-left: 1px solid rgba(31, 32, 31, 0.5);
}

#features-container {
  font-size: 1rem;
  text-align: Center;
  width: 99%;
  margin: auto;
  margin-bottom: 5px;
  /* margin-top: 10px; */
  background: #4f4f4f;
  box-shadow: 1px 1px 3px #1a191961;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  overflow-x: hidden;
  padding-top: 16px;
  margin-top: -10px;
  font-family: "district_prolight";
  -webkit-transition: background 0.15s ease-in-out;
  -moz-transition: background 0.15s ease-in-out;
  -ms-transition: background 0.15s ease-in-out;
  -o-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

#site-settings-container {
  font-size: 1rem;
  text-align: Center;
  width: 99%;
  margin: auto;
  margin-bottom: 5px;
  /* margin-top: 10px; */
  background: #4f4f4f;
  box-shadow: 1px 1px 3px #1a191961;
  letter-spacing: 1px;
  border-radius: 3px;
  cursor: pointer;
  overflow-x: hidden;
  padding-top: 16px;
  margin-top: -10px;
  font-family: "district_prolight";
  -webkit-transition: background 0.15s ease-in-out;
  -moz-transition: background 0.15s ease-in-out;
  -ms-transition: background 0.15s ease-in-out;
  -o-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  padding-left: 10px;
  padding-right: 10px;
  h2 {
    font-size: 16px;
  }
}

#manually-add-switch {
  margin-top: 20px;
}

#add-tiny-device-input {
  width: 75%;
  margin: auto;
  padding-top: 6px;
  padding-bottom: 6px;
  border-radius: 3px;
  margin-bottom: 20px;
  max-width: 600px;
}

#add-tiny-device-submit {
  border-radius: 2px;
}

.site-action-button {
  background: #1f201f;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.add-action-container, .add-trigger-container {
  padding: 10px 5px 5px 20px;
}

.modal {
  z-index: 1040;
  display: block;
  height: 100%;
  width: 100%;
  padding-left: 4px;
}

.modal-content {
  color: white;
  background-color: #202020;
}

#devices-modal .modal-content, #scenes-modal .modal-content {
  color: white;
  background-color: #333232;
  width: 100%;
  display: block;
  /* height: 99%; */
  /* max-height: 1000px; */
  /* max-width: 800px; */
  padding: 20px;
  overflow: auto;
}

.search-bar {
  position: relative;
  width: 90%;
  margin: 10px auto;
  border-radius: 5px;
  border: 4px solid white;
  font-size:13px;
  padding: 2px 7px;
  box-shadow: 0px 1px 1px 1px rgba(151, 147, 147, 0.6);
}

#tagged-device-search-bar {
  width: 64%;
  left: 145px;
  margin: 0;
}

#total-power {
  display: none;
}

.device-modal > .modal-dialog {
  margin-top: 6%;
}

.phd-device-check {
  margin-right: 10px;
}

.phd-device-li {
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid grey;
}

#show-parental-phd {
  width: 30px;
  display: inline-block;
}

.show-parental-phd {
  padding: 10px;
  > label {
    display: inline-block;
    position: relative;
    top: 3px;
  }
}
.page-content-container{
  width: auto;
  top: calc(46px + env(safe-area-inset-top));
  -webkit-transition: left 0.3s ease-in-out;
  transition: left 0.3s ease-in-out;
}
#page-content {
  //80 px is height of the header that we need to offset after we calc 100%
  height: -webkit-calc(100% - 80px);
  height:    -moz-calc(100% - 80px);
  height:         calc(100% - 80px);
  position: relative;
  display: block;
  width: 99%;
  height: 100%;
  margin: auto;
  overflow: auto;
  // -webkit-overflow-scrolling: touch;
}

#locations-header {
  width: 100%;
  /* border-radius: 6px; */
  position: relative;
  background: #202020;
  /* box-shadow: 2px 2px 5px black; */
  top: -5px;
  height: 55px;
  z-index: 3;
  color: white;
  text-align: center;
  display: none;
}

#advanced-device-settings {
  float: right;
  right: -24px;
  font-size: 35px;
  top: 30px;
}

.device-dp-input {
  display: inline-block;
  width: 100%;
  margin-bottom: 0px;
  border-radius: 5px;
  /* border: 4px solid white; */
  color: black;
  background: white !important;
  padding: 2px 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.36);
}

.action-trig-item-container > {
  label {
    margin: 6px 0px;
    position: relative;
    display: block;
  }
  input {
    top: 7px;
    position: relative;
  }
}

.action-input-container {
  display: none;
  width: 100%;
  position: relative;
  > {
    .settings-input {
      display: inline-block;
      margin: unset;
      margin-left: 18px;
      color: white;
    }
    input {
      width: 83% !important;
      position: relative;
      margin: 0px !important;
      bottom: 22px;
    }
  }
}

.settings-input > i {
  display: block;
  color: white;
}

td > input {
  margin: 5px auto;
  padding: 5px;
  background: #333232;
  color: white;
  text-align: center;
}

#data-table {
  font-size: 14px;
  margin: auto;
  z-index: 20;
}

#device-info {
  color: white;
  max-width: 340px;
  /* margin-top: -3px; */
  /* padding-left: 10px; */
  padding-bottom: 0px;
  padding-top: 13px;
  top: 12px;
  position: relative;
  clear: both;
}

#setpoint-readout, #setpoint-table-readout {
  margin: 5px 0px 5px 0px;
  padding: 8px;
  font-size: 16px;
  background: #1f201f;
  border-radius: 5px;
  display: none;
}

#device-info > span {
  display: block;
}

.main-device-rssi {
  padding: 5px;
}

#device-header {
  border-radius: 6px;
  position: relative;
  background: #4f4e4f;
  box-shadow: 2px 2px 5px #1f201f;
  top: -5px;
  height: 80px;
  margin: auto;
  padding: 10px 56px 10px 56px;
  color: white;
  text-align: center;
}

#breadcrumbs {
  cursor: pointer;
  display: none;
  font-size: 13px;
  color: #a6b9ff;
}

.loc-crumb-separator {
  padding: 3px;
  font-size: 14px;
}

#location-title {
  font-size: 32px;
  position: absolute;
  width: 100%;
  left: 0px;
  line-height: 1.4;
}

#device-title {
  font-size: 32px;
  position: absolute;
  width: 100%;
  top: 17px;
  left: 0px;
  line-height: 1.4;
  left: -8px;
  top: 0px;
  bottom: 15px;
  line-height: 1.2;
  svg{
    font-size: 25px !important;
    margin-right: 12px;
  }
}

#location-title.with-crumbs {
  top: 12px;
}

#site-header .fa {
  display: none;
}

.feature-title {
  font-size: 16px;
  top: 5px;
  position: relative;
}

.feature-settings > input {
  margin: 0 !important;
}

.scene-title {
  position: relative;
  /* margin-bottom: 12px; */
  bottom: 15px;
}

#device-title, .scene-title {
  /* top:25px; */
  font-size: 25px;
}

#device-title {
  left: 5px;
  line-height: 1.2;
  > .device-id {
    font-size: 1rem;
    position: relative;
    display: block;
  }
}

#location-data {
  position: relative;
  color: white;
  display: block;
  color: #adaaad;
  max-width: 32%;
  left: 75px;
  top: 8px;
  font-size: 14px;
  text-align: left;
  float: left;
}

#sub-location-data {
  position: relative;
  color: white;
  display: block;
  bottom: 10px;
  max-width: 32%;
  font-size: 14px;
  text-align: left;
}

.loc-header-data {
  display: block;
}

.loc-controls-title {
  cursor: pointer;
}

#site-header {
  .fa {
    padding-right: 8px;
  }
}

#site-name {
  cursor: pointer;
}

.device-main-info > span > label {
  display: block;
  color: white;
  font-size: 19.2px;
  font-size: 0.8rem;
  margin: 0;
}

#devices-advanced-settings {
  cursor: pointer;
}

li#no-devices-display {
  padding: 5px;
  top: 5px;
}
#loc-settings-icon {
  font-size: 22px;
  float: right;
  top: 10px;
  right: 0px;
  position: absolute
}

#location-title > .component-name {
  cursor: pointer;
  top: 7px;
  position: relative;
  float: left;
}
#location-title.with-crumbs>.component-name{
  top: 1px;
}
#location-title>#breadcrumbs{

  margin-top: 2px;

}

.scenes-hidden>i {
  margin-right: 5px;
}



.scenes-hidden{
  color: white;

  margin-top: 6px;
  position: relative;
  display: block;
}

#device-name-page-content, .scene-title > .component-name {
  cursor: text;
}

.add-device-rssi{
  right:40px;
  color:white;
  position:relative;
}

#page-icon {
  left: 0;
}

.remove-after {
  position: relative;
  top: 10px;
}

#feature-list {
  position: relative;
  width: 100%;
  display: block;
  /* padding: 10px; */
  background: #4f4e4f;
}

.setting-label {
  margin: 20px 0px 11px 0px;
  /* display:inline; */
  font-size: 16px !important;
}

#breadcrumbs, .component-name {
  margin-left: 20px;
}

.scene-title > .component-name {
  margin-left: 0px;
}

#device-title .component-name {
  margin-left: 0px;
  bottom: 4px;
  position: relative;
}

.button {
  float: right;
  width: 100px;
  border-radius: 3px;
  font-size: 16px;
  margin: 10px;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
  &:hover {
    box-shadow: inset 0px 0px 3px 0px #000000b8;
  }
}

.error {
  color: #f14646;
}

#content-area {
  position: relative;
  padding: 6px 0;
  margin-left: 80px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 5px;
  // -webkit-overflow-scrolling: touch;
}

#on-screen-status-error {
    text-align: left;
    //width: 99%;
    //position: relative;
    //border-radius: 3px;
    margin: auto;
    //margin-bottom: 10px;
    padding: 7px 20px 7px 20px;
    //display: none;
    height: 95%;
    overflow: auto;
    span{
        color: white;
    }
}

.component-name-input {
  padding: 3px 3px 0px 10px;
  margin: 5px;
  height: 32px;
  display: inline-block;
  border-radius: 5px;
  width: unset;
  color: white;
  box-shadow: inset 0px 0px 5px #171718;
  border: none;
  background: #969494;
  position: relative;
}

.scene-list-item > .list-item-content > .component-name-input, .device-list-content > .component-name-input {
  background: #269cff;
}

.dev-error-lastping {
  display: block;
  padding: 2px 22px;
}

.loc-error {
  > .fa-warning {
    font-size: 1rem;
    margin-right: 6px;
  }
  margin: 10px 0px;
  svg{
    margin-right: 10px;
    margin-left: -7px;
  }
}

#status-error-dropdown {
  position: absolute;
  right: 0px;
  top: 7px;
  font-size: 1.5rem;
}

#location-error-text {
  color: #D8000C;
  position: relative;
  width: 95%;
  margin-top: 12px;
}

#locations-error-icon {
  color: #c1030f;
  font-size: 1.5rem;
}

div {
  &#scene-edit-container {
    height: 500px;
    max-width: 1200px;
    min-width: 760px;
  }
  &#trig-container, &#action-container {
    width: 100%;
    /* display: inline-block; */
    display: block;
    /* float: left; */
    /* min-height: inherit; */
    // z-index: 10;
    /* height: 100%; */
    margin-bottom: 18px;
    /* overflow: auto; */
    // overflow: hidden;
    /* margin: 1px; */
    border-radius: 5px;
    background: #dbdbdb;;
  }
  &#action-list-container, &#trigger-list-container {
    position: relative;
  }
}

.action-trig-item-container {
  padding: 15px;
  /* text-align: right; */
  position: relative;
  display: block;
  border-bottom: 1px solid #333232;
}

.trig-act-label {
  font-weight: bold;
  width: 100%;
  background: #202020;
  border-radius: 3px;
  position: relative;
  padding: 10px;
  clear: both;
  text-align: center;
  box-shadow: 0px 2px 0px rgba(37, 37, 37, 0.69);
}

#trig-action-container {
  height: 98%;
  display: block;
  position: relative;
  padding-right: 10px;
  width: 100%;
  top: 30px;
//   overflow: auto;
}
.add-action-button {
  float: right;
  margin-top: 17px;
}
.add-actions-container {
  padding: 0 32px;
  margin-top: 20px;
}
.add-action-selector{
  display: block !important;
  width: 195px;
  margin-top: 10px;
}
.resizeTextField{
  font-size: 10px !important;
}
.var1Input{
    max-width: 90px;
    padding: 10px 8px;
    &.zones {
        margin-left: 10px;
    }
    &.channels {
        max-width: 100px;
        margin-left: 10px;
    }
    &.level {
        margin-left: 10px;
        max-width: 135px;
    }
    &.fadeValue {
        margin-left: 10px;
        max-width: 115px;
    }
}
.add-actions-done-button{
  margin-top: 10px;
}
.scene-control {
  color: white;
  padding: 5px;
  background: #4f4e4f;
  margin-top: 11px;
  position: relative;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 0px 3px 1px black;
}

.loc-controls-title {
  font-size: 25px;
  padding: 5px;
  position: relative;
  display: block;
}

#ident-device-main {
  padding: 15px;
  font-size: 30px;
  color: white;
  /* position: absolute; */
  top: 0px;
  position: relative;
  float: left;
}

.feature-settings {
  border-radius: 3px;
  padding: 21px 30px 33px 30px;
  top: 4px;
  background: #2c2c2c;
  margin: 5px;
  white-space: nowrap;
  position: relative;
  text-align: left;
  box-shadow: inset 1px 1px 4px #1f201fab;
  > .lock-button {
    top: -18px;
  }
}

.main-device-lastping {
  font-size: .7em;
}

.device-main-info {
  display: inline-block;
  position: relative;
  span {
    position: relative;
  }
}

.old-device-icon-main {
  padding-left: 3px;
}

.device-data-setting > label {
  display: inine-block;
  font-size: 1.1rem;
}

.device-data-setting-title {
  font-size: 15px;
  padding: 0px 0px 3px 3px;
  position: relative;
  background: #1f201f;
  display: block;
}

.loc-controls-container {
  padding: 0px 10px 0px 10px;
  &.independent {
    padding: 3px 10px 10px 10px;
    border-radius: 5px;
    margin-top: 15px;
    background: rgb(31, 32, 31);
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

.sub-loc-controls {
  background: #1f201f;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
  color: white;
  border-radius: 6px;
  box-shadow: 1px 1px 2px #0202027d;
}

.device-data-setting {
  background: #1f201f;
  padding: 10px;
  margin-top: 15px;
  text-align: center;
  color: white;
  border-radius: 6px;
  box-shadow: 1px 1px 2px #0202027d;
  text-align: left;
  margin-right: 3px;
  padding: 8px;
}

.scene-control.slider {
  /* background: none; */
  /*box-shadow: unset;*/
  /* width: 50%; */
  /* position: relative; */
  /* height: 200px; */
  /* top: 10px; */
  /* margin-top: 30px; */
  /* float: right; */
}

.lock-button, .lock-button-scene {
  /* width: 50px; */
  font-size: 1.2rem;
  font-size: 20px;
  /* cursor: pointer; */
  /* margin-right: 12px; */
  /* position: relative; */
  position: absolute;
  top: 33px;
  right: -6px;
  z-index: 10;
  cursor: pointer;
  /* left: 68px; */
  /* top: 14px; */
  top: 57px;
  padding: 10px;
  position: relative;
}

.scene-settings-options > span {
  /* width: 50%; */
  padding: 6px 10px;
  box-shadow: 1px 1px 1px black;
  /* height: 100%; */
  background: #4f4e4f;
  display: inline-block;
  position: relative;
  margin: 6px;
  border-radius: 3px;
}

.slider-level {
  position: absolute;
  top: 37%;
  left: 30%;
}

.cloud-sync-readouts-container {
  padding-top: 15px;
  margin-left: 5px;
}

.modal-open .modal {
  display: contents !important;
  /* overflow-x: hidden; */
  overflow-y: hidden;
}

ul#scene-setting-dropdown {
  right: 100px;
  position: relative;
  right: 11px;
  left: unset;
}

i#scene-settings-dropdown-button {
  position: relative;
  left: 83px;
  border-radius: 5px;
  &.fa-close {
    background: #333333;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
}

button{
  text-transform:none !important;
}


div#scene-settings {
  width: 60px;
  position: absolute;
  top: 20px;
  right: 78px;
  font-size: 30px;
}
.App{
  transition-property: margin-right;
  transition-duration: 250ms;
}
#finalize-feature-settings{
  background-color: #248f2d;
}
#location-settings-menu{
  background-color: #4f4f4fd6;
  color: white;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 80px;
  max-height: 530px;
  overflow-y: auto;
  #close-location-menu-settings{
    span{
      height: 24px;
      width: 24px;
    }
  }
  .subheader-buttons{
    display: inline;
    float: right;
    padding-right: 25px;
    #finalize-feature-settings{
      float: none;
    }
  }
  .checkbox{
    margin-right: 15px;
  }
  div{
    color: white;
    padding-right: 0px;
    font-size:18px;
  }
  hr, p, span{
    color: white;
  }
  .subheader{
    font-size: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    background-color: #666;
    button{
      float: right;
    }
  }
  .fab-container{
    button{
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  #label{
    font-size: 16px;
  }
  .room-type-option-label{
    padding-right: 40px;
    font-size: 15px
  }
  .secondary-list-item-container{
    span,div{
      display: inline-block;
    }
    .not-applied{
      color:#a7a7a7;
    }
    .location-readout-container{
      margin-right:40px;
      .location-readout-single{
        margin-right: 10px;
        margin-left: 10px;
        .icon{
          margin-right:4px;
        }
      }
      .not-applied{
        color:#a7a7a7;
        span{
          color:#a7a7a7;
        }
      }
    }
  }
}
.room-type-icon{
  margin-right: 16px !important;
}
#landing-page{
  .content-container{
    width: 450px;
    margin: auto;
    margin-top: 300px;
    border-radius: 12px;
    .logo-container{
      width: 350px;
      margin: auto;
    }
    .text-container{
      text-align: center;
      margin-top: 20px;
    }
    .primary-button{
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 20px;
      padding-right: 20px;
      margin: auto;
      margin-top: 25px;
      font-size: 20px;
    }
    .secondary-div{
      margin: auto;
      margin-top: 10px;
      text-align: center;
      button{
        color: white;
        text-align: center;
        background-color: transparent;
        margin: auto;
      }
    }
  }
}
.mobile-stepper{
  background-color: transparent !important;
  width: 50px;
  margin: auto;
}
.mobile-stepper-container{
  margin-bottom: 15px;
}
.stepper-container-insetup{
  max-width: 700px;
  margin: auto;
  .stepper{
    background-color: transparent;
    span{
      color: white !important;
    }
    svg{
      color: #4f4f4fd6;
    }
    .activeStep{
      svg{
        color: #0074d4;
      }
    }
  }
  .finish-button-container{
    button{
      background-color: #388e3c;
    }
  }
}

.stepper-container{
  max-width: 700px;
  margin-top: 20%;
  margin: auto;
  .stepper{
    background-color: transparent;
    span{
      color: white !important;
    }
    svg{
      color: #4f4f4fd6;
    }
    .activeStep{
      svg{
        color: #0074d4;
      }
    }
  }
  .finish-button-container{
    button{
      background-color: #388e3c;
    }
  }
}
.first-location{
  .text-field-container{
    margin-top: 30x;
  }
  #location-name-input-page-content{
    text-align: center;
    margin-top: 15px;
    font-size: 23px;
    background-color: #4f4f4fd6;
    color: white;
  }
  .button-container{
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .room-type-container{
    p{
      font-size: 22px;
      color: white;
    }
    svg{
      margin-bottom: 0px;
      font-size: 22px;
      margin-right: 10px;
    }
  }
}
#label{
  svg{
    margin-right: 10px !important;
  }
}
.App header{
  height: 50px;
}
.location-settings-menu-fab{
  button{
    margin-right: 15px;
  }
}
.edit-actions{
  display: inline-block;
  float: right;
  position: relative;
}
.remove-action{
  cursor:pointer;
  svg{
    color:red;
  }
}
.show-action {
  margin-top: 10px;
  margin-left: 10px;
  display: inline-block;
  float: right;
  position: relative;

}
.validation-location{
  display:block;
  margin: 10px 0px 0px 5px;
  .validation-header{
    font-size:20px;
  }
  .validate-scene-issues{
    margin-left: 20px;
    display: block;
    position: relative;
  }
  .scene-issue-title {
    display: block;
    position: relative;
    margin-left: 20px;
    margin-top:3px;
  }
  .issue-title{
    display:block;
    margin-left:60px;
  }
  .issue-contents{
    display:block;
    margin-left:75px;
  }
  .scene-issues{
    display:block;
    position:relative;
    margin-left:20px;
    margin-top: 1px;
    margin-bottom: 5px;
    .issue-title{
      margin-left:40px;
    }
  }
  .validate-missing-features{
    display:block;
    margin:10px;
    .issue-title{
      margin-left:10px;
    }
  }

  .validation-passed{
    margin-left:20px;
  }
}

.item-link,
.item-element  {
    display: block;
    text-decoration: none;
    width: 100%;
    &.subheader {
        padding-left: 10px;
        padding-bottom: 3px;
        line-height: 16px;
        text-transform: uppercase;
        font-weight: 500;
        .item-text {
            font-size: 11px;
            color: rgba(48, 47, 55, 0.6);
        }
    }
    &.active {
        background-color: rgba(0,0,0,.2);
    }
    .item-container {
        user-select: none;
        cursor: pointer;
        width: 100%;
        .item-flex {
            display: flex;
            align-items: center;
            min-height: 27px;
            font-size: 14px;
            padding: 2px 14px 2px 5px;
            width: 100%;
            .item-icon-dropdown {
                flex-shrink: 0;
                flex-grow: 0;
                border-radius: 3px;
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 0px;
                .item-button-dropdown {
                    user-select: none;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    border-radius: 3px;
                }
            }
            .item-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                flex-grow: 0;
                width: 22px;
                height: 18px;
                margin-right: 4px;
            }
            .item-text {
                flex: 1 1 auto;
                white-space: nowrap;
                min-width: 0px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: flex;
                align-items: center;
                .item-paragraph{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .group-action {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                flex-grow: 0;
            }

        }
    }
}
.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInUp {
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes slideOutDown {
    from {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    to {
        visibility: hidden;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

.slideOutDown {
    -webkit-animation-name: slideOutDown;
    animation-name: slideOutDown;
}

.dialog-menu {
    position: absolute;
    top: 25px;
    left: 0;
}

.dialog-button-close {
    position: absolute;
    top: 25px;
    right: 0;
}

.box-position-bottom {
    position: absolute;
    bottom: 0;
}

.react-code-input {
    padding-top: 12px;
    display: block !important;
    max-width: 265px;
    margin: 0 auto;
    height: 60px;
    input {
        padding: 0 0 0 8px !important;
        float: left;
    }
}

pre.terminal__json-pretty
{
    padding: 0px;
    margin: 5px;
    color: white;
    background-color: transparent;
    // span { display: block; }
    .string { color: green; }
    .number { color: darkorange; }
    .boolean { color: blue; }
    .null { color: magenta; }
    .key { color: blueviolet; }
}

.button-container {
    margin: 0 auto;
    padding-top: 20px;
    text-align: center;
}

.center-mfa-setup{
  margin-top: 40% !important;
}

.text-white {

    color: white !important;
    h2, h4 {
        color: white !important;
    }
}

.no-gutter {
    margin: 0 !important;
}

.side-gutter {
  margin: 0 5px !important;
}

.accordion-panel-summary {
    background-color: #4f4f4fd6 !important;
    border-radius: 12px !important;
    min-height: 48px !important;
    > div:first-child {
        margin: 12px 0 !important;
    }
}

.accordion-panel {
    width: 100%;
    background-color: #242424 !important;
    box-shadow: none !important;
}

.accordion-panel-container {
    padding: 0px !important;
    padding-top: 16px !important;
}

.var1-select {
    margin: 15px 10px !important;
    max-width: 120px !important;
    min-width: 100px !important;
}

.location-validation-list {
    overflow-y: scroll;
    max-height: 440px;
    text-align: left;
    color: white;
}
