$font-stack:    Helvetica, sans-serif;
$primary-color: #333;

$background-color-1: #2d2d2d;
$background-color-2: #595959;
$background-color-3: #269cff;
$nav-blue: #088fff;

$default-border-radius: 5px;

$primary-button-color: #0074d4;
$secondary-button-color: dimgrey;

$nav-icons-width: 65px;

$spin-animation-speed: .8s;