@import "main";

.config-tables{
    margin-top:25px;
    margin-left:20px;
    margin-right:20px;
    margin-bottom: 1px;
    // max-height: 1200%;
}
.config-tables-charts{
    margin-left:20px;
    margin-bottom: 1px;
}
.top-readout, .bottom-readout{
    margin-top:10px;
    margin-left:20px;
    margin-right:20px;
    margin-bottom: 1px;
    max-height: 300px;
}
.device-detail-header{
    color: black;
    // margin-bottom: 10px;
    font-size: 16px;
}

.tooltip-ota {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}

.tooltip-ota .tooltiptext-ota {
    visibility: hidden;
    width: 240px;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    //padding: 5px 0;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 99999;
}
.tooltip-ota:hover .tooltiptext-ota {
    visibility: visible;
}
