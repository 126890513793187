/* @font-face {
	font-family:'Font-Name';
	src:url('font-name.eot');
	src:url('font-name.eot?#iefix') format('embedded-opentype'),
		url('font-name.woff') format('woff'),
		url('font-name.ttf') format('truetype'),
		url('font-name.svg#font-name') format('svg');
    font-weight:normal;
    font-style:normal;
} */
@font-face {
    font-family: 'district_prolight';
    src: url('../fonts/district-pro/distprolt-webfont.eot');
    src: url('../fonts/district-pro/distprolt-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/district-pro/distprolt-webfont.woff') format('woff'),
         url('../fonts/district-pro/distprolt-webfont.ttf') format('truetype'),
         url('../fonts/district-pro/distprolt-webfont.svg#district_prolight') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'district_promedium';
    src: url('../fonts/district-pro/distpromd-webfont.eot');
    src: url('../fonts/district-pro/distpromd-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/district-pro/distpromd-webfont.woff') format('woff'),
         url('../fonts/district-pro/distpromd-webfont.ttf') format('truetype'),
         url('../fonts/district-pro/distpromd-webfont.svg#district_promedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'district_prothin';
    src: url('../fonts/district-pro/distproth-webfont.eot');
    src: url('../fonts/district-pro/distproth-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/district-pro/distproth-webfont.woff') format('woff'),
         url('../fonts/district-pro/distproth-webfont.ttf') format('truetype'),
         url('../fonts/district-pro/distproth-webfont.svg#district_prothin') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'district_probook';
    src: url('../fonts/district-pro/distprobk-webfont.eot');
    src: url('../fonts/district-pro/distprobk-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/district-pro/distprobk-webfont.woff') format('woff'),
         url('../fonts/district-pro/distprobk-webfont.ttf') format('truetype'),
         url('../fonts/district-pro/distprobk-webfont.svg#district_probook') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'district_prodemi';
    src: url('../fonts/district-pro/distprodm-webfont.eot');
    src: url('../fonts/district-pro/distprodm-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/district-pro/distprodm-webfont.woff') format('woff'),
         url('../fonts/district-pro/distprodm-webfont.ttf') format('truetype'),
         url('../fonts/district-pro/distprodm-webfont.svg#district_prodemi') format('svg');
    font-weight: normal;
    font-style: normal;
}
