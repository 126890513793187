.App {
  text-align: center;
  height:100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
  padding-top: env(safe-area-inset-top); /* iOS 11.2 */
}

#root {
    margin-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    margin-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.giraffe-influx {
  margin-top: 40px !important;
  width: 95%;
  height: calc(50vh - 20px);
  margin: auto;
  display: grid;
}

.giraffe-influx .empty {
    align-self: center;
    color: lightgray;
    justify-self: center;
}

.giraffe-influx div, canvas{
  text-align: left;
}

.giraffe-influx .giraffe-static-legend-column-value {
  max-width: 100% !important;
  overflow: initial !important;
}

@import 'scss/index.scss';
